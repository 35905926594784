import React from "react";

function AudioIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      fill="none"
      viewBox="0 0 14 18"
      className={props.className}
    >
      <path
        fill="#000"
        d="M5.957 14.835c.528 0 .972-.18 1.333-.542.361-.361.542-.806.542-1.333V9.835h2.5V8.168H6.999v3.23a1.537 1.537 0 00-.49-.24 2.007 2.007 0 00-.552-.073c-.528 0-.972.18-1.333.541a1.812 1.812 0 00-.542 1.334c0 .527.18.972.542 1.333.36.361.805.542 1.333.542zm-3.958 2.5c-.459 0-.85-.163-1.177-.49a1.608 1.608 0 01-.49-1.177V2.335c0-.459.163-.85.49-1.177.327-.326.719-.49 1.177-.49h6.666l5 5v10c0 .458-.163.85-.489 1.178a1.6 1.6 0 01-1.177.489h-10zM7.832 6.5h4.167L7.832 2.335V6.5z"
      ></path>
    </svg>
  );
}

export default AudioIcon;