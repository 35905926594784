
import { createContext, useContext, useState } from 'react';



const PathContext = createContext();

export const usePath = () => {
  const context = useContext(PathContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};



export const PathProvider= ({ children }) => {
  const [path, setPath] = useState("");

  const previouspath = (pathname) => {   
    setPath(pathname);
  };

  return (
    <PathContext.Provider value={{ path, previouspath }}>
      {children}
    </PathContext.Provider>
  );
};
