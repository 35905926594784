import React from 'react'
import { useLocation, useNavigate ,useParams} from 'react-router-dom'
const SideNav = ({aisights}) => {
    const params=useParams();
    const location=useLocation()
    const {id}=params
    const navigate=useNavigate()
    const sideNavOpt=aisights?
    [{name:"Health",value:"aiinsights/healthinfo"},{name:"Family History",value:"aiinsights/familyinfo"},{name:"Hobbies & general interests",value:"aiinsights/hobbiesinfo"},{name:"Lifestyle",value:"aiinsights/lifestyleinfo"},{name:"Other AI insights",value:"aiinsights/otheraiinfo"}]
    :
    [{name:"Basic information",value:"basicinfo"},{name:"Hobbies & Lifestyle",value:"hobbiesinfo"},{name:"Medical history",value:"medicalinfo"},{name:"Surgical history & disabilities",value:"surgicalinfo"}]
    const handleSideNavigate=(value)=>{
        if(value==="basicinfo"){
    navigate(`/user/${id}`)
        }else{
            navigate(`/user/${id}/${value}`)
        }
    }
  return (
    <div className="w-[100%] card_shadow flex flex-col gap-[30px]  py-10-scale-mob md:py-10-scale-924 pl-30-scale-mob md:px-30-scale-924"> 
    {sideNavOpt.map(val=><div key={val.value} className={""}><p  onClick={()=>handleSideNavigate(val.value)} className={`text-16-mob md:text-16-scale-924 cursor-pointer font-[600]  p-10-scale-mob ${(location.pathname.includes(val.value))||(val.value==="basicinfo"&&location.pathname===`/user/${id}`)?"text-black-100 bg-[#F5F5F5] rounded-[5px]":"text-[#8A8A8A]"} `}>{val.name}</p></div>)}

    </div>
  )
}

export default SideNav