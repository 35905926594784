import React,{useEffect} from 'react'
import SideNav from '../SideNav'
import MedicalConditionIcon from '../../../../icons/HealthCareIcon'
// import "../users.css"
import "./aiinsights.css"

import DietIcon from '../../../../icons/DietIcon';
import HealthCare from '../../../../icons/HealthCare';
import BedIcon from '../../../../icons/BedIcon';
//redux
import { useSelector } from 'react-redux';
const LifeStyle = () => {
  const ainsights = useSelector((state) => state.common.aiinsights);


  return (
    <div className="flex justify-between">
      <div className='w-[20%]'>
      <SideNav  aisights={true}/>
      </div>
          <div className="w-[77%] flex flex-col form-fields">
                      <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob flex flex-col form-gap-20">
                         
                              <div className="flex items-center gap-x-[10px] ">
                                  <DietIcon />
                                  <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[10px]'>Diet</p>

                             
                            </div>
                            {ainsights?.lifestyle?.diet&&<div className="bg-white-100 border border-[#EBEBEB] rounded-[8px] shadow-sm">
                                  <p className='p-12-scale-mob md:p-10-scale-924 text-16-scale-mob md:text-16-scale-924'>
                                    {ainsights?.lifestyle?.diet}
                                  </p>
                              </div>}


                      </div>
                      <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob flex flex-col form-gap-20">
                          
                              <div className="flex items-center gap-x-[10px]  ">
                                  <HealthCare />
                                  <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[10px]'>Exercise</p>

                              </div>
                              {ainsights?.lifestyle?.exercise&&<div className="bg-white-100 border border-[#EBEBEB] rounded-[8px] shadow-sm">
                                  <p className='p-12-scale-mob md:p-10-scale-924 text-16-scale-mob md:text-16-scale-924'>
                                    {ainsights?.lifestyle?.exercise}
                                  </p>
                              </div>}

                      </div>
                      <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob flex flex-col form-gap-20">
                          
                              <div className="flex items-center gap-x-[10px]  ">
                                  <BedIcon />
                                  <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[10px]'>Sleep routine</p>

                              </div>
                              {ainsights?.lifestyle?.sleep_routine&&<div className="bg-white-100 border border-[#EBEBEB] rounded-[8px] shadow-sm">
                                  <p className='p-12-scale-mob md:p-10-scale-924 text-16-scale-mob md:text-16-scale-924'>
                                    {ainsights?.lifestyle?.sleep_routine}
                                  </p>
                              </div>}
                            

                      </div>

         
            </div>
</div>
  )
}

export default LifeStyle