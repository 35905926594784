import React from "react";
import { usePagination, DOTS } from "./usePagination";

import BackIcon from "../../icons/BackIcon";
import PreviousPageIcon from "../../icons/PreviousPageIcon";
import NextPageIcon from "../../icons/NextPageIcon";

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 0,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    currentPage !== lastPage
      ? onPageChange(currentPage + 1)
      : onPageChange(currentPage);
  };

  const onPrevious = () => {
    currentPage !== 1
      ? onPageChange(currentPage - 1)
      : onPageChange(currentPage);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
   
<div className="flex items-center  justify-center w-full gap-x-[10px]">
    <button className="w-30-scale-mob md:w-30-scale-924 h-30-scale-mob md:h-30-scale-924 flex items-center justify-center " onClick={onPrevious} disabled={currentPage===1}>
        <PreviousPageIcon/>
    </button>
    {paginationRange.map(val=><div key={val} className={`w-30-scale-mob md:w-30-scale-924 h-30-scale-mob md:h-30-scale-924 flex items-center ${val===currentPage?"bg-[black] text-white-100":""} justify-center text-[16px] cursor-pointer`}
    onClick={()=>onPageChange(val)}>
        {/* {currentPage} */}{val}
    </div>)}
    <button className="w-30-scale-mob md:w-30-scale-924 h-30-scale-mob md:h-30-scale-924 flex items-center justify-center " onClick={onNext} disabled={currentPage===lastPage}>
        <NextPageIcon/>
    </button>
</div>
      );
};

export default Pagination;



