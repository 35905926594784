import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
    //   width="37"
    //   height="41"
      fill="none"
      viewBox="0 0 37 41"
      className="w-37-scale-mob lg:w-37-scale lg:h-41-scale h-25-scale-mob "
    >
      <path
        fill="url(#paint0_linear_405_2801)"
        d="M29.246 10.156s.964-.863 1.379-2.218a4.969 4.969 0 00.35-1.844c0-.347-.036-.685-.102-1.012A2.66 2.66 0 0029.763 0c-.94 0-1.766.484-2.24 1.22A5.863 5.863 0 0025.38.817c-3.093 0-5.6 2.364-5.6 5.28 0 1.874 1.036 3.523 2.6 4.462-.076.024-2.463.847-3.653 3.297 0 0-1.895-2.51-4.416-2.902 1.995-.809 3.396-2.678 3.396-4.855 0-2.916-2.507-5.28-5.6-5.28-3.092 0-5.6 2.364-5.6 5.28 0 1.644.8 3.116 2.051 4.084 0 0-1.858.014-3.176 1.477 0 0 2.337 14.567 16.928 18.448 0 0-.076.511-1.432.841 0 0 .317.584 2.153.82.059.008.123.013.184.021 2 .22-13.459 3.449-21.678-13.812 0 0-.755-.79-.83.965 0 0-.376 1.393 0 3.44 0 0 6.659 14.349 17.733 17.79 0 0 7.266-1.537 12.81-9.846v-6.331s-1.847-4.466-5.319.549c0 0-.78 1.206-1.086-.184 0 0 .259-3.367 3.688-3.367 0 0 2.338-.293 3.96 2.526l.264 4.611s1.507-.438 2.148-2.967c0 0-2.393-7.376-8.634-5.269 0 0-1.47.182-.15-1.3 0 0 5.43-4.812 9.69 2.38 0 0 .754 1.739.885-.35-.002 0-.117-7.87-7.45-10.47z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_405_2801"
          x1="0.538"
          x2="36.693"
          y1="20.085"
          y2="20.085"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.03" stopColor="#3D3C3A"></stop>
          <stop offset="0.149" stopColor="#383733"></stop>
          <stop offset="0.297" stopColor="#5F5D53"></stop>
          <stop offset="0.494" stopColor="#33312D" stopOpacity="0.5"></stop>
          <stop offset="0.752" stopColor="#68655A"></stop>
          <stop offset="1" stopColor="#1C1B1A" stopOpacity="0.807"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Logo;