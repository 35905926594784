import React from "react";

function CallForwardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
    >
      <path
        fill="#1C1B1F"
        d="M16.95 19c-2.083 0-4.142-.454-6.175-1.363-2.033-.908-3.883-2.195-5.55-3.862-1.667-1.667-2.954-3.517-3.862-5.55C.453 6.192 0 4.133 0 2.05c0-.3.1-.55.3-.75.2-.2.45-.3.75-.3H5.1a.93.93 0 01.625.238.914.914 0 01.325.562l.65 3.5c.033.267.025.492-.025.675-.05.183-.142.342-.275.475L3.975 8.9c.333.617.73 1.213 1.187 1.787.459.575.963 1.13 1.513 1.663.517.517 1.058.996 1.625 1.438.567.441 1.167.845 1.8 1.212l2.35-2.35a1.4 1.4 0 01.588-.338 1.61 1.61 0 01.712-.062l3.45.7c.233.067.425.188.575.363A.88.88 0 0118 13.9v4.05c0 .3-.1.55-.3.75-.2.2-.45.3-.75.3zM15 8l-1.45-1.4 1.6-1.6H10V3h5.15L13.6 1.45 15 0l4 4.05L15 8z"
      ></path>
    </svg>
  );
}

export default CallForwardIcon;