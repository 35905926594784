import React, { useState, useEffect, useRef } from 'react';
import './Audioplayer.css'; // Import your CSS file
import CallForwardIcon from '../../icons/CallForwardIcon';
import { formatString } from '../../methods';
//redux
import { useSelector } from 'react-redux';

const AudioPlayer = ({ call }) => {
  const formData = useSelector((state) => state.users.user_details);
  const [duration, setDuration] = useState(null);
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      const handleLoadedMetadata = () => {
        setDuration(audio.duration);
      };

      audio.addEventListener('loadedmetadata', handleLoadedMetadata);

      return () => {
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, [call.file]);



  return (
    <div className="md:w-[70%] w-[100%]">
      <div className="flex gap-[15px] items-center text-16-scale-mob md:text-16-scale-924 font-[600]">
        <CallForwardIcon/>
        <p>{call.call_type&&`${formatString(call.call_type)} Call`} </p>
      </div>

      <div className="flex gap-[50px] pt-[20px] pl-20-scale-924">
      <div className="text-14-scale-mob md:text-14-scale-924 ">
        <span className="font-[600]">Phone Number - </span>
       {/* {call.id} */}
       {formData?.phone_number && formData.phone_number.replace(/^91/, '')}
      </div>
      <div className="text-14-scale-mob md:text-14-scale-924 ">
        <span className="font-[600]">Unique call id - </span>
       {call.id}
      </div>
      <div className="text-14-scale-mob md:text-14-scale-924 ">
        <span className="font-[600]">Duration - </span>
        {duration !== null ? `${duration.toFixed(2)}s` : '-'}
      </div>
      </div>
      {/* <button className="play-button" onClick={handlePlayClick}>
        Play
      </button> */}
      <audio ref={audioRef} src={call.file} controls className="w-[100%]" />
    </div>
  );
};

export default AudioPlayer;
