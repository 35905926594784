import React, { useEffect, useState, useRef, useCallback } from 'react'
import AddFillIcon from '../../../icons/AddFillIcon'
import GlobalTextArea from '../../../components/GlobalTextArea'
import { useForm } from 'react-hook-form';
import CloseIcon from '../../../icons/CloseIcon';
import { useDispatch, useSelector } from 'react-redux';
import { addNotes, editNotes, getAllNotes } from '../../../redux/actions/usersactions';
import moment from 'moment';
import "../users.css"
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import EditIcon from '../../../icons/EditIcon';
import { formatString } from '../../../methods';
const Notes = () => {
  const refs = useRef({});
  const allNotes = useSelector((state) => state.users.notes);
  const [viewNote, setViewNote] = useState("")
  //loader for button
  const [loading, setLoading] = useState(false)
  // console.log(allNotes)
  const dispatch = useDispatch();
  const [noteId, setNoteId] = useState(null)
  const { id } = useParams()
  const [formatedNotes, setFormatedNotes] = useState(null)

  const methods = useForm({ mode: "onSubmit", defaultValues: {} });
  const { control, formState: { errors }, setValue, getValues, reset } = methods;
  const submitNotes = async (data) => {
    setLoading(true)
    if (noteId) {
      try {
        await dispatch(editNotes(noteId.id, { note: data.note }));
        await dispatch(getAllNotes(id))
        document.getElementById('my_modal_7').close();
        checkIfTruncated();
        reset()
        toast.success("Note updated successfully")
        setNoteId(null)
        // navigate(`/user/add/${id}/surgicalinfo/${details_id}`)
      } catch (err) {
        console.log(err)
        toast.error(err.message)
      }
    } else {
      try {
        await dispatch(addNotes(id, { note: data.note, tag: "notes_tab" }));
        await dispatch(getAllNotes(id))
        document.getElementById('my_modal_7').close();

        reset()
        toast.success("Note added successfully")

        // navigate(`/user/add/${id}/surgicalinfo/${details_id}`)
      } catch (err) {
        console.log(err)
        toast.error(err.message)
      }
    }
    setLoading(false)
  }
  const fetchNotes = async () => {
    try {
      await dispatch(getAllNotes(id))
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchNotes()
  }, [])
  useEffect(() => {
    if (allNotes) {    
      const groupedByDate = allNotes.results.reduce((acc, item) => {     
        const dateKey = new Date(item.created_at).toISOString().split('T')[0];
        if (!acc[dateKey]) {
          acc[dateKey] = [];
        }
        acc[dateKey].push(item);
        return acc;
      }, {});
      // setFormatedNotes(groupedByDate)
      const sortedDates = Object.keys(groupedByDate).sort((a, b) => new Date(b) - new Date(a));

      // Create a new object with sorted dates
      const sortedGroupedByDate = sortedDates.reduce((acc, date) => {
        acc[date] = groupedByDate[date];
        return acc;
      }, {});
      setFormatedNotes(sortedGroupedByDate)
    }

  }, [allNotes])
  const handleEditNote = (note, id,tag,date) => {
    console.log(date)
    setValue("note", note);
    setNoteId({id:id,tag:tag,date:moment(date).format("DD MMM YYYY")})
    document.getElementById('my_modal_7').showModal()
  }
  const [truncatedNotes, setTruncatedNotes] = useState({});



  const checkIfTruncated = useCallback(() => {
    const newTruncatedNotes = {};
    Object.keys(refs.current).forEach((id) => {
      const element = refs.current[id];
      if (element) {
        newTruncatedNotes[id] = element.scrollHeight > element.clientHeight;
      }
    });
    setTruncatedNotes(newTruncatedNotes);
  }, []);

  useEffect(() => {
    // Check for truncation on mount and when notes change
    checkIfTruncated();
    window.addEventListener('resize', checkIfTruncated);
    return () => window.removeEventListener('resize', checkIfTruncated);
  }, [checkIfTruncated, formatedNotes]);
  return (
    <>
      <div>
        <div className='flex justify-between md:pb-20-scale-924 pb-20-scale-mob'>
          <p className="md:text-24-scale-924 text-24-scale-mob font-[600]">Notes</p>
          <button type="button" className="md:h-30-scale-924 h-30-scale-mob md:px-10-scale-924 px-10-scale-mob border border-1 border-black-100 rounded-[6px]  text-14-scale-mob md:text-14-scale-924 bg-[#F5F5F5]" onClick={() => document.getElementById('my_modal_7').showModal()}>
            <div className="flex items-center gap-x-[5px]"><AddFillIcon /> Add New Notes</div></button>

        </div>
        {/* {form.map(val =>
                <div key={val.created_at} className="form-fields flex flex-col">
                    <div className="bg-[#F6F6F6] p-10-scale-mob md:p-10-scale-924 ">
                        {val.created_at}
                    </div>
                    <div  className="md:pl-20-scale-924 pl-20-scale-mob flex flex-col gap-[10px]">
                      <div>
                        <span className="bg-[#EBEBEB] p-10-scale-mob md:p-10-scale-924 rounded-[20px] ">{val.notes.note_type}</span>
                        </div>
                        <p>{val.notes.note}</p>
                    </div>
                </div>)} */}
        {formatedNotes && Object.keys(formatedNotes).map(val =>
          <div key={val} className="form-fields flex flex-col  ">

            <div className="bg-[#F6F6F6] p-10-scale-mob md:p-10-scale-924 font-[600]">
              {moment(val).format("DD MMM YYYY")}
            </div>
            <div className="dashed-border-large md:pl-20-scale-924 pl-20-scale-mob">
              {formatedNotes[val].map(note => <div key={note.id} className="pb-[50px]"> <div className="p-20-scale-924 flex flex-col gap-[10px] card_shadow" >
                <div className="flex justify-between ">
                  <span className="bg-[#EBEBEB] p-10-scale-mob md:p-10-scale-924 rounded-[20px] ">{note.tag && note.tag === "call" ? "Notes from call" : formatString(note.tag)}</span>
                  <button className="border border-[2px] border-black-100 md:px-10-scale-924 px-10-scale-mob">
                    <div className="flex items-center gap-x-[1px]" onClick={() => handleEditNote(note.note, note.id,note.tag,note.created_at)}>
                      <div className='pt-[5px]'>
                        <EditIcon />
                      </div>
                      <p className="md:text-18-scale-924 text-18-scale-mob ">Edit</p>
                    </div>
                  </button>
                </div>
                <div className="">
                        <p   ref={(el) => (refs.current[note.id] = el)} className={`w-[80%] ${viewNote===note.id?"":"line-clamp-4"}`}>{note.note}</p>
                        {truncatedNotes[note.id] && (
                        <span className="font-[600] underline text-[black] cursor-pointer" onClick={()=>setViewNote(note.id===viewNote?"":note.id)}>{note.id===viewNote?"View less":"View more"}</span>
                        )}
                        </div>
            
              </div></div>)}
            </div>
          </div>)}
      </div>
      {/* modal */}
      <dialog className="modal rounded-[0]" id="my_modal_7">
        <div className="modal-box w-[95%] md:min-w-[800px] p-0 rounded-[0]">
          <div className="flex justify-between border-b-[1px] border-[black] items-center py-[15px] px-[15px]" >
           
     <h3 className="md:text-18-scale-924 text-18-scale-mob font-inter">
      {noteId ? (
        <>
          Edit notes - <span className="font-semibold">{noteId.date}{" "}</span>
          <span className="bg-[#EBEBEB] p-10-scale-mob md:p-10-scale-924 rounded-[20px] text-14-scale-mob md:text-14-scale-924">{noteId.tag && noteId.tag === "call" ? "Notes from call" : formatString(noteId.tag)}</span>

        </>
      ) : (
        <>
          Add notes - <span className="font-semibold">{moment().format("DD MMM YYYY")}</span>
        </>
      )}
    </h3>
            <button onClick={() => { document.getElementById('my_modal_7').close(); reset() }} ><CloseIcon /></button>

          </div>
          <div className="py-[15px]">


            <GlobalTextArea control={control} name="note" required={true}
              rules={{ required: "Please enter note" }} errors={errors.note}

              placeholder="Type your notes here"
              //  className="font-[!400] !border-0 note-input"
              className="h-[300px] note-input"
            />


            <hr className="border-black-100" />
            <div className="flex justify-end pt-[20px] px-[20px] gap-[20px]">
              <button className="border border-black-100 w-[168px] md:w-[168px] h-[43px] rounded-[4px] font-[600]" onClick={() => { document.getElementById('my_modal_7').close(); reset(); setNoteId(null) }}>Discard</button>

              <button className="bg-[black] opacity-[50%] text-[white] w-[168px] md:w-[168px] h-[43px] rounded-[4px]" onClick={methods.handleSubmit(submitNotes)} disabled={loading}>{loading ? <span className="loading loading-spinner loading-sm"></span> : noteId ? "Update" : "Add"}</button>
            </div>

          </div>
          {/* <hr className="bg-[black] text-[black] color-[black]"/> */}
        </div>
        <form method="dialog" className="modal-backdrop">
          <button onClick={() => { reset(); setNoteId(null) }}>close</button>
        </form>
      </dialog>
    </>
  )
}

export default Notes