import React from 'react'
import GLobalSelect from '../../components/GlobalSelect'
import GlobalInput from '../../components/GlobalInput'
import DeleteIcon from '../../icons/DeleteIcon';

const ContactsForm = ({ control, index, errors,id ,is_agreed,handleChangeAgrred,relationshipOptions,handleContactDel}) => {
 
  return (
    <div >
      <div className="flex justify-between">
      <p className="text-24-scale-mob lg:text-24-scale-924 font-[600] ">Emergency contact {index}</p>
     {index!==1&& <button onClick={()=>handleContactDel(id)}><DeleteIcon/></button>}
      </div>
      <div className="flex flex-wrap justify-between form-fields pt-20-scale-924">
        <div className="w-[100%] md:w-[45%]">
          <GLobalSelect name={`relationship_${id}`} label="RelationShip" control={control} options={relationshipOptions}
            labelStyles={"text-20-scale-mob lg:text-16-scale-924 font-[600] "} className={"h-50-scale-mob lg:!h-45-scale-924"} required={true} rules={{ required: "Relationship is required" }} errors={errors[`relationship_${id}`]} />
        </div>
        <div className="w-[100%] md:w-[45%]">
          <GlobalInput name={`name_${id}`} label="Name" control={control} labelStyles={"text-20-scale-mob lg:text-16-scale-924 font-[600]"} className={"h-50-scale-924-mob lg:!h-45-scale-924 "} required={true} rules={{ required: "Name is required" }} errors={errors[`name_${id}`]} />
        </div>
        <div className="w-[100%]">
          <div className="w-[100%] md:w-[45%]">
            <GlobalInput name={`contact_number_${id}`} label="Contact Number" control={control} labelStyles={"text-20-scale-mob lg:text-16-scale-924 font-[600]"} className={"h-50-scale-924-mob lg:!h-45-scale-924 "} required={true} rules={{
              required: "Contact Number is required", pattern: {
                value: /^\d{10}$/,
                message: 'Please Enter Valid Contact Number',
              }
            }} errors={errors[`contact_number_${id}`]} phone_number={"true"} />
          </div>


        </div>
        <div className='flex gap-[10px] items-center'>
          <input type="checkbox" className="w-[30px] h-[30px] custom-checkbox" checked={is_agreed} onChange={()=>handleChangeAgrred(id)} />
          <label htmlFor="medical-info" className="text-14-scale-mob lg:text-14-scale-924 " >{index === 1 ? "I agree to share my personal and health updates with this contact who is my primary personal caregiver" : "I agree to share my personal and health updates with this contact"}</label>
        </div>
      </div>

    </div>
  )
}

export default React.memo(ContactsForm)