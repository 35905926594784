import React from "react";

function SortIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      fill="none"
      viewBox="0 0 18 14"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7.5 8.5h-6m4.5-3H1.5m3-3h-3m7.5 9H1.5M14.25 13V1m0 12l2.25-2.25M14.25 13L12 10.75M14.25 1l2.25 2.25M14.25 1L12 3.25"
      ></path>
    </svg>
  );
}

export default SortIcon;