import * as events from "../Events";

export const sendOtp = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/account-login/generate_otp/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        const resError = await response.json();
        let jsonError=""
        if(typeof(resError)==="object"){
          console.log("resError","hello",resError)
          jsonError= JSON.stringify(resError)
        }else{
          jsonError=resError
        }
        if (jsonError) {
          throw new Error(jsonError);
        }
      }  else {
        const resData = await response.json();

        const otp = resData.data;
        dispatch({ type: events.GET_OTP });
        return otp ? true : false;
      }
    } catch (err) {
  
      throw new Error(err.message);
    }
  };
};

//submit otp
export const submitOtp = (data) => {
    return async (dispatch) => {
      try {
        const response = await fetch(`${events.API_URL}/account-login/verify_otp/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        if (!response.ok) {
          const resError = await response.json();
          let jsonError=""
          if(typeof(resError)==="object"){
            console.log("resError","hello",resError)
            jsonError= JSON.stringify(resError)
          }else{
            jsonError=resError
          }
          if (jsonError) {
            throw new Error(jsonError);
          }
        }  else {
          const resData = await response.json();
          //  localStorage.setItem("token",resData.token)
          //  localStorage.setItem("id",resData.id);
          //  localStorage.setItem("name",resData.username)
          dispatch({ type: events.SEND_OTP });

          return resData ? resData : false;
        }
      } catch (err) {
        console.log(err,"err")
        throw new Error(err.message);
      }
    };
  };
  //ADD_AGENT
  export const addAgents = (data) => {
    return async (dispatch) => {
      try {
        const response = await fetch(`${events.API_URL}/users/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        if (!response.ok) {
          const resError = await response.json();
          let jsonError=""
          if(typeof(resError)==="object"){
            console.log("resError","hello",resError)
            jsonError= JSON.stringify(resError)
          }else{
            jsonError=resError
          }
          if (jsonError) {
            throw new Error(jsonError);
          }
        }  else {
          const resData = await response.json();
          //  localStorage.setItem("token",resData.token)
          //  localStorage.setItem("id",resData.id);
          //  localStorage.setItem("name",resData.username)
          dispatch({ type: events.SEND_OTP });

          return resData ? resData : false;
        }
      } catch (err) {
        console.log(err,"err")
        throw new Error(err.message);
      }
    };
  };