import React from 'react'
import SortIcon from '../../icons/SortIcon'
import './GlobalTable.css'; 

const GlobalTable = ({ headings, data, headingStyles, dataStyles, tableStyles, loading, handleSort, sort,rowStyles, isSticky }) => {
  return (
    // <div className="table-wrapper bg-[green] p-0 min-h-[150px]">
    <div className="table-wrapper min-h-[150px]">
      {!loading ? data && data.length > 0 ? <table className={`table min-w-[100%] font-inter font-[500] ${isSticky?"stick-table":""} ${tableStyles}`}>
        <thead>
          <tr className={`h-[52px] ${rowStyles}`}>
            {headings.map(val => val.sort ?
              <th key={val.name} className={`text-14-scale-mob md:text-14-scale text-muted-foreground  ${val.headingStyles} ${headingStyles} ${val.name==="Actions"?"sticky-column":""}`}><div className='flex gap-[5px]'><p >{val.name}</p>{val.sort && <div>
                <div className="dropdown dropdown-end" >
                  <div tabIndex={0} role="button" >
                    {/* <div className=""> */}
                    <SortIcon />
                    {/* </div> */}
                  </div>
                  
                  <ul

                    tabIndex={0}
                    className="menu menu-sm dropdown-content bg-base-100 z-[1] mt-3 w-52 p-2 shadow-xl w-[150px]" >

                    <li onClick={() => handleSort(-1)} className='text-2xl py-2'><a className={`${sort === "-created_at" ? "text-sky-400" : "text-muted-foreground"}`}>Newest first</a></li>
                    <li onClick={() => handleSort(1)} className='text-2xl'><a className={`${sort === "created_at" ? "text-sky-400" : "text-muted-foreground"}`}>Oldest first</a></li>
                  </ul>
                </div>

              </div>

              }</div></th> : <th key={val.name} className={`text-14-scale-mob md:text-14-scale text-muted-foreground  ${val.headingStyles} ${headingStyles}`}>{val.name}</th>)}
          </tr>
        </thead>

        <tbody>

          {data && data.length > 0 && data.map((val, index) => <tr key={val + index} className={`h-[52px] ${rowStyles}`}>

            {headings.map((item, index) => <td key={item + index} className={`text-14-scale-mob md:text-14-scale font-[500] text-[#09090B]  text-nowrap ${item.dataStyles} ${dataStyles}`}>{item.render ? item.render(val) : val[item.value] || "-"}</td>)}


          </tr>)}
        </tbody>
      </table> :
        <div className="flex items-center justify-center min-h-[400px] w-[100%]"> <p className='text-center'>No Records Found</p></div> :

        <div className="flex items-center justify-center min-h-[300px]">

          <span className="loading loading-spinner loading-lg"></span>
        </div>}
        </div>
    // </div>
  )
}

export default GlobalTable