import './App.css';
import Users from './screens/users/Users';
import Login from './screens/login/Login';
import { Routes, Route,  BrowserRouter as Router } from "react-router-dom";
import UserDetails from './screens/DetailsForm/Index';
import BasicInfo from './screens/DetailsForm/BasicInfo';
import MedicalInfo from './screens/DetailsForm/MedicalInfo';
import SurgicalInfo from './screens/DetailsForm/SurgicalInfo';
import HobbiesInfo from './screens/DetailsForm/HobbiesInfo';
import Summary from './screens/DetailsForm/Summary';
import Basic from './screens/users/view/Index';
import Generalinfo from './screens/users/view/Generalinfo';
import ViewMedicalInfo from './screens/users/view/MedicalInfo';
import ViewSurgicalInfo from './screens/users/view/Surgicalinfo'
import ViewHobbiesInfo from "./screens/users/view/HobbiesInfo"
import Notes from './screens/users/view/Notes';
//toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserManagement from './screens/users/UserManagement';
import CallInfo from './screens/users/view/CallInfo';
import { PathProvider } from './states/PathContext';
import Family from './screens/users/view/aiinsights/Family';
// import Health from './screens/users/view/aiinsights/Health';
import Hobbies from './screens/users/view/HobbiesInfo';
import LifeStyle from './screens/users/view/aiinsights/LifeStyle';
import OtherAiSights from './screens/users/view/aiinsights/OtherAiSights';
import AiHobbies from './screens/users/view/aiinsights/Hobbies';
import AiHealth from './screens/users/view/aiinsights/Health';
function App() {
  const token = localStorage.getItem("token")
  const ProtectedRoutes = ({ children }) => {
    if (token) {
      return children
    } else {
      return <Login />
    }
  }

  return (
    <>

      <ToastContainer 
      style={{zIndex: 200000}}    
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" />

      {/* <Users/> */}
      {/* <Login/> */}
      <PathProvider >
      <Router>
        <Routes>
          <Route
            path="/"
            element={<Login />}
          />
          <Route
            path="/users"
            element={<ProtectedRoutes><Users /></ProtectedRoutes>}
          />
          <Route
            path="/usermanagement"
            element={<ProtectedRoutes><UserManagement /></ProtectedRoutes>}
          />
          

          {/* view Routes */}
          <Route exact path={`/user/:id`} element={<ProtectedRoutes><Basic /></ProtectedRoutes>}>
            <Route exact index element={<ProtectedRoutes><Generalinfo /></ProtectedRoutes>} />
            <Route exact path="medicalinfo" element={<ProtectedRoutes><ViewMedicalInfo /></ProtectedRoutes>} />
            <Route exact path="surgicalinfo" element={<ProtectedRoutes><ViewSurgicalInfo /></ProtectedRoutes>} />
            <Route exact path="hobbiesinfo" element={<ProtectedRoutes><ViewHobbiesInfo /></ProtectedRoutes>} />
            <Route
              path="/user/:id/notes"
              element={<ProtectedRoutes><Notes /></ProtectedRoutes>}
            />
            <Route
              path="/user/:id/callsinfo"
              element={<ProtectedRoutes><CallInfo /></ProtectedRoutes>}
            />
             <Route
              path="/user/:id/aiinsights/familyinfo"
              element={<ProtectedRoutes><Family /></ProtectedRoutes>}
            />
             <Route
              path="/user/:id/aiinsights/healthinfo"
              element={<ProtectedRoutes><AiHealth /></ProtectedRoutes>}
            />
             <Route
              path="/user/:id/aiinsights/hobbiesinfo"
              element={<ProtectedRoutes><AiHobbies /></ProtectedRoutes>}
            />
            <Route
              path="/user/:id/aiinsights/lifestyleinfo"
              element={<ProtectedRoutes><LifeStyle /></ProtectedRoutes>}
            />
             <Route
              path="/user/:id/aiinsights/otheraiinfo"
              element={<ProtectedRoutes><OtherAiSights /></ProtectedRoutes>}
            />
            {/* <Route exact path="medicalinfo" element={<Medical />} />
                  <Route exact path="surgicalinfo" element={<SurgicalInfo />} />
                  <Route exact path="hobbiesinfo" element={<HobbiesInfo />} />
                  <Route exact path="summary" element={<Summary />} /> */}
          </Route>
          {/* add routes */}
          <Route exact path={`/user/add/:id`} element={<ProtectedRoutes><UserDetails /></ProtectedRoutes>}>
            <Route exact index element={<BasicInfo />} />
            <Route exact path="medicalinfo" element={<MedicalInfo />} />
            <Route exact path="surgicalinfo" element={<SurgicalInfo />} />
            <Route exact path="hobbiesinfo" element={<HobbiesInfo />} />
            <Route exact path="summary" element={<Summary />} />
          </Route>
        </Routes>

      </Router>
      </PathProvider>
    </>
  );
}

export default App;

