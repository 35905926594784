import React, { useRef, useEffect, useState, useCallback } from 'react'
import NavBar from '../../components/navbar/NavBar'
import GlobalTable from '../../components/Table/GlobalTable';
import "./users.css"
import CallIcon from '../../icons/CallIcon';
import AddIcon from '../../icons/AddIcon';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import { getAllPrivateContacts } from '../../redux/actions/usersactions';
import moment from 'moment';
import EyeIcon from '../../icons/EyeIcon';
import { debounce } from 'lodash';

import EditIcon from '../../icons/EditIcon';
import Pagination from '../../components/pagination/Pagination';
import GlobalInput from '../../components/GlobalInput';
import SearchIcon from '../../icons/SearchIcon';
import { compareTime } from '../../methods';

const UserManagement = () => {
  const token = localStorage.getItem("token")
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const contacts = useSelector((state) => state.users.contacts)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const methods = useForm({
    mode: "all",
    defaultValues: { user_type: { label: "New Users", value: "new" } }
  }
  );
  const { control, formState: { errors }, setValue, getValues, reset } = methods;


  const headings = [{ name: "Unique ID", value: "id" }, { name: "Date of adding", value: "date_of_adding", render: (val) => <p>{moment.utc(val.created_at).format('MMMM DD, YYYY')}</p> },
  { name: "Name", value: "full_name" }, { name: "Agent assigned", value: "agent_name" },
  { name: "Age", value: "age" }, { name: "Phone number", value: "phone_number",render: (val) => <p>{val.phone_number && val.phone_number.replace(/^91/, '')}</p> }, { name: "Date registered", value: "created_at", render: (val) => <div>{val.registration_date&&moment.utc(val.registration_date).format("MMMM DD,YYYY")}</div>, sort: true }, 
  { name: "Last call", value: "last_call_date", render: (val) => <p>{val.last_call_date ? moment.utc(val.last_call_date).format('MMMM DD, YYYY') : "-"}</p>},
  { name: "Last interaction", value: "last_interaction", render: (val) => <p className={`${val.last_interaction_date&&compareTime(val.last_interaction_date)==="Greater than 48 hours"?"text-[red]":"text-[black]"} `}>{val.last_interaction_date ? compareTime(val.last_interaction_date) : "-"}</p>},
  { name: "Upcoming call", value: "upcoming_call",render: (val) => <p>{val.followup_date ? moment.utc(val.followup_date).format('MMMM DD, YYYY') : "-"}</p> },

  {
    name: "Actions", headingStyles: "text-center", render: (val) => <div className="flex gap-x-[10px] justify-center items-center">

      <>
        <button onClick={() => navigate(`/user/${val.id}`)}><EyeIcon /></button>
      </>

    </div>
  }]

  //fetchContacts
  const fetchContacts = async () => {
    try {
      setLoading(true)
      await dispatch(getAllPrivateContacts(currentPage, "registered", sort, search))


    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchContacts()
  }, [currentPage, sort, search])
  const handleSort = (order) => {
    // setSort(sort==="-updated_at"?"updated_at":"-updated_at")
    if (order === -1) {
      setSort("-created_at")
    } else {
      setSort("created_at")
    }
  }
  const handleSearch = (e) => {
    if (e.code === "Enter") {
      setSearch(getValues("search"))
    }
  }
  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearch(getValues("search"))
    }, 500),
    []
  );
  if (!token) {
    navigate("/")
  }
  return (
    <div>
      <NavBar />
      <div className="flex flex-col lg:flex-row pt-91-scale-mob lg:pt-91-scale justify-between px-50-scale">
        <div className="w-[100%]">
          <div className='flex justify-between pb-[20px]'>
            <h1 className="text-24-scale-mob lg:text-24-scale font-inter font-[600] ">User Table</h1>

            {/* <p className="absolute inset-y-0 left-2  flex items-center pr-[15px]  text-16-scale-mob md:text-16-scale-924 font-600 border-r-2 border-black-100   font-[600]"> */}
            <div className="relative" >
              <div className="absolute inset-y-0 left-2  flex items-center z-[20] "> <SearchIcon /></div>
              <GlobalInput control={control} name="search" search={true} className="border-[#E4E4E7] text-black-100 !w-[400px]" placeholder="Search by user name or ID here"
                onKeyPress={(e) => handleSearch(e)} onChange={debouncedSearch} />
            </div>

          </div>

          <div className="custom-box-shadow rounded-[10px] pl-[15px] ">
            {/* <GlobalTable headings={headings}
              data={contacts ? contacts.results : []}
              // data={[]}
              loading={loading}
              handleSort={handleSort}
              sort={sort}
            /> */}
            <GlobalTable headings={headings}
            isSticky={true}
              sort={sort}
              handleSort={handleSort}
              data={contacts ? contacts.results : []}
              loading={loading}
            />
          </div>

        </div>




      </div>
      {!loading && <div className="py-20-scale-mob md:py-20-scale-924">
        <Pagination
          totalCount={
            contacts ? contacts.count : 0
          }
          currentPage={currentPage}
          pageSize={10}
          onPageChange={((currentPage) => {
            setCurrentPage(currentPage)
          })}

        />
      </div>}


    </div>
  )
}

export default UserManagement