// import React,{useState} from 'react'
// import DatePicker from 'react-datepicker';
// import CalendarIcon from '../../icons/CalendarIcon';
// import "react-datepicker/dist/react-datepicker.css";import "react-datepicker/dist/react-datepicker.css";



// const GlobalCalendar = ({label,...props}) => {
//     const [startDate, setStartDate] = useState(new Date());
//     const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
//         <div className="!w-[100%]">
//          {label&&<div className="pb-[5px] pl-[5px] w-[100%]">
//           <label className={props.labelStyles} >{label}</label>
//         </div>}
//         <div className="border border-[2px] h-50-scale-924-mob lg:!h-50-scale-924 border-[#747474] rounded-[4px]  !w-[500px] flex items-center pl-[10px]" onClick={onClick} ref={ref}>
//             {/* <div className='flex  pr-[20px] items-center'> */}
//                 <div className='w-[90%]'>{value}</div> 
//                 <div><CalendarIcon/></div> 
//          {/* </div> */}
//         </div>
//       {/* <input className="border border-[2px] h-50-scale-924-mob lg:!h-50-scale-924 border-[#747474] rounded-[4px] pl-[10px] !w-[100%]" onClick={onClick} ref={ref} value={value} readOnly /> */}
//        </div>
//     ));
//     return (
      
//       <DatePicker
//         selected={startDate}
//         onChange={(date) => setStartDate(date)}
//         customInput={<ExampleCustomInput />}
//         // className='text-[50px]'
//       />
   
//     );
//   };

// export default GlobalCalendar


// import { useState } from 'react';
// import Calendar from 'react-calendar';



// function GlobalCalendar() {
//   const [value, onChange] = useState(new Date());

//   return (
//     <div>
//       <Calendar onChange={onChange} value={value} />
//     </div>
//   );
// }
// export default GlobalCalendar

import React, { useState,useRef,useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import CalendarIcon from '../../icons/CalendarIcon';
import "./GlobalCalendar.css"

const MyCalendarComponent = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const calendarRef = useRef(null); 

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={calendarRef}>
      {props.label&&<div className="pb-[5px] pl-[5px] relative">
          <label className={props.labelStyles}>{props.label}{props.required&&<span className="text-[#FF4664] pl-[5px]">*</span>}</label>
        </div>}
    {props.type!=="dob"
    ?  <div className={`border border-[2px] h-50-scale-924-mob lg:!h-45-scale-924 border-[#747474] rounded-[4px]  w-[100%] flex items-center pl-[10px] justify-between ${props.disabled?"disabled-icon ":""}`}  onClick={!props.disabled?handleButtonClick:()=>setIsOpen(false)} >
                <div className="text-16-scale-mob lg:text-16-scale-924">{props.selectedDate?moment(props.selectedDate).format('DD/MM/YYYY'):""}</div> 
               <div className="pr-[20px]"><CalendarIcon/></div> 
        </div>:
        <div className="border border-[2px] h-50-scale-924-mob lg:!h-45-scale-924 border-[#747474] rounded-[4px] w-[100%] flex items-center pl-[10px]">
        <input
          type="text"
          // value={inputValue}
          value={props.inputValue}
          onChange={(e)=>{props.handleInputChange(e);!isOpen&&setIsOpen(true)}}
          onClick={handleButtonClick}
          className="w-[90%] border-none outline-none bg-transparent text-16-scale-mob lg:text-16-scale-924"
        />
        <div className="pr-[20px] cursor-pointer" onClick={handleButtonClick}>
          <CalendarIcon />
        </div>
      </div>
}
       
    
      {isOpen && (
        <div  className={`absolute  z-20  ${props.calenderStyles}`}>
        <Calendar       
          onChange={(value)=>{props.handleDateChange(value);  setIsOpen(!isOpen);}}
          value={props.selectedDate}
          maxDate={props.maxDate}
          minDate={props.minDate}
          
        />
        </div>
      )}
    </div>
  );
};

export default MyCalendarComponent;
