import * as events from "../Events";
const token=localStorage.getItem("token")

export const getProvinces = () => {
    // console.log(token)
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/provinces/`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' 
          }
       
      });
      if (!response.ok) {
        const resError = await response.json();
        if (resError) {
          throw new Error(resError);
        }
      } else {
        const resData = await response.json();

        // const users = resData;
        dispatch({ type: events.GET_ALL_PROVINCE,payload:resData });
        return resData ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getCities = (id) => {
    // console.log(token)
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/cities/?province_id=${id}`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' 
          }
       
      });
      if (!response.ok) {
        const resError = await response.json();
        if (resError) {
          throw new Error(resError);
        }
      } else {
        const resData = await response.json();

        // const users = resData;
        dispatch({ type: events.GET_CITIES,payload:resData });
        return resData ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
//GET_AI_INSIGHTS
export const getAiinsights = (phone) => {
  // console.log(token)
return async (dispatch) => {
  try {
    const response = await fetch(`http://164.52.209.83:5006/user_insights?user_id=${phone}`, {
      method: "GET",
      headers: {
                   'Content-Type': 'application/json' 
        }
     
    });
    if (!response.ok) {
      dispatch({ type: events.GET_AI_INSIGHTS,payload:null });
      const resError = await response.json();
      let jsonError=""
      if(typeof(resError)==="object"){
        console.log("resError","hello",resError)
        jsonError= JSON.stringify(resError)
      }else{
        jsonError=resError
      }
      if (jsonError) {
        throw new Error(jsonError);
      }
     
    }  else {
      const resData = await response.json();

      dispatch({ type: events.GET_AI_INSIGHTS,payload:resData });
      return resData ? resData : false;
    }
  } catch (err) {
    throw new Error(err.message);
  }
};
};