import React,{useRef,useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Logo from '../../icons/Logo'
import DashboardIcon from '../../icons/DashboardIcon'
import BookIcon from '../../icons/BookIcon'
import { useForm } from 'react-hook-form';
//icons
import CloseIcon from '../../icons/CloseIcon'
//css
import "./navbar.css"
import DownArrowIcon from '../../icons/DownArrowIcon'
//components
import GlobalInput from '../GlobalInput'

import { toast } from 'react-toastify'
//redux
import { addAgents } from '../../redux/actions/authactions'
import { useDispatch } from 'react-redux'


const NavBar = () => {
  const modalRef=useRef()
  const dispatch=useDispatch()
  const [addLoading,setAddLoading]=useState(false)
  const methods = useForm({
    mode: "all",
    defaultValues: { user_type: { label: "New Users", value: "new" } }
  }
  );
  const { control, formState: { errors }, reset, getValues } = methods;
  const location = useLocation();
  const navigate = useNavigate()
  const handleLogout = () => {
    localStorage.clear();
    navigate("/")
  }
  const onSubmit=async(data)=>{
    setAddLoading(true)
    try{
     await dispatch(addAgents({username:data.full_name,phone_number:`91${data.phone_number}`}))
      toast.success("Agent Added Successfully");
      reset();
      document.getElementById('my-modal-10').close();
    }catch(err){
      toast.error(err.message)
    }
    setAddLoading(false)

  }
  return (
    <>
    <div className="navbar 72-scale-mob lg:h-72-scale font-inter border-b-[1px] border-[#C4C4C4] px-20-scale fixed bg-[white] z-[50]">
      <div className="navbar-start">
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
            {/* <Logo/> */}
            Menu
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">

            <li><a className="text-14-scale-mob lg:text-14-scale flex px-20-scale-mob lg:20-scale py-10-scale-mob lg:py-10-scale gap-x-4 items-center  cursor-pointer"><DashboardIcon />Dashboard</a>
            </li>
            <li><a className="text-14-scale-mob lg:text-14-scale flex px-20-scale-mob lg:20-scale py-10-scale-mob lg:py-10-scale gap-x-4  items-center whitespace-nowrap  cursor-pointer"><BookIcon />User management</a>
            </li>
          </ul>
        </div>
        <div className="hidden lg:flex gap-x-[15px] items-center">
          <div className="flex gap-x-2 items-center text-14-scale-mob lg:text-14-scale lg:px-25-scale py-10-scale-mob lg:py-10-scale">
            <Logo />
            <a className="text-16-scale">Swarn Aayu</a>
          </div>

          <a className={`nav-links nav-icons text-14-scale-mob lg:text-14-scale cursor-pointer ${location.pathname.includes("users") ? "nav-icons-focused" : ""}`} onClick={() => navigate("/users")}><DashboardIcon className="nav-icons" /> Dashboard</a>
          <a className={`nav-links nav-icons text-14-scale-mob lg:text-14-scale cursor-pointer  ${(location.pathname.includes("user/")||location.pathname==="/usermanagement") ? "nav-icons-focused" : ""}`}  onClick={() => navigate("/usermanagement")}><BookIcon className="nav-icons" /> User management</a>
        </div>
      </div>

      <div className="navbar-end flex gap-[30px] items-center">
        <div className="flex -space-x-1 rounded-full border border-[1px] border-[#E5E7EB] bg-[#F0F1F4] w-[40px] h-[40px] items-center justify-center ">
          <p className="text-xl">{localStorage.getItem("name") ? localStorage.getItem("name").charAt(0).toUpperCase() : ''}</p>
        </div>
        <div>
          <p className="text-16-scale-924 font-[600] ">{localStorage.getItem("name")&&localStorage.getItem("name").replace(/\b\w/g, char => char.toUpperCase())}</p>
        </div>
        <div className="dropdown dropdown-end">
          <div tabIndex={0} role="button" >
            <div className="">
              <DownArrowIcon />
            </div>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content bg-base-100 z-[1] mt-3 w-52 p-2 shadow-lg w-[150px]">
            <li onClick={handleLogout} className='pb-2' ><a className='text-14-scale-mob lg:text-14-scale-924 '>Logout</a></li>
           {localStorage.getItem("is_staff") === "true" && <li   className='pb-2'>
              {/* <a className='text-14-scale-mob lg:text-14-scale-924'>Add Admin</a> */}
              <label htmlFor="my-modal-10" className='text-14-scale-mob lg:text-14-scale-924' onClick={() => document.getElementById('my-modal-10').showModal()}>Add Agent</label>

              </li>}
          </ul>
        </div>

      </div>
    </div>
    <dialog className="modal" ref={modalRef} id="my-modal-10">
        <div className="modal-box w-[95%] md:min-w-[400px]">
          <div className="flex justify-between border-b-[1px] border-[black] items-center pb-[10px]" >
            <h3 className="text-[20px] font-inter pb-[10px]">Add New User</h3>

            <button onClick={() => { document.getElementById('my-modal-10').close(); reset() }} ><CloseIcon /></button>

          </div>
          <div className="p-[10px]">
            <div className="flex flex-col gap-[10px]">
              <div className="w-[100%] ">
                <GlobalInput control={control} name="full_name" label="Full name" required={true}
                  rules={{ required: "Name is required" }} errors={errors.full_name} className="font-[!400]"
                />
              </div>
             
              
              <div className="w-[100%]">
                <GlobalInput name="phone_number" label="Phone Number" control={control} className="font-[!400]"
                  required={true} rules={{
                    required: "Mobile Number is required", pattern: {
                      value: /^\d{10}$/,
                      message: 'Please Enter Valid Phone Number',
                    }
                  }} errors={errors.phone_number} phone_number={"true"} />
                {/* <GlobalInput control={control} name="phone_number" label="Phone Number" required={true} rules={{ required: "Phone Number is required" }} errors={errors.phone_number} /> */}
              </div>
              
            </div>
            <div className="flex justify-center pt-[20px]">
              <button className="bg-[black] text-[white] w-[150px] md:w-[150px] h-[43px] rounded-[4px]" onClick={methods.handleSubmit(onSubmit)}  disabled={addLoading}>{addLoading ? <span className="loading loading-spinner loading-sm"></span> : "Add"}</button>
            </div>

          </div>
          {/* <hr className="bg-[black] text-[black] color-[black]"/> */}
        </div>
        <form method="dialog" className="modal-backdrop">
          <button onClick={() => reset()}>close</button>
        </form>
      </dialog>
    </>
  )
}

export default NavBar