import React from 'react';
import { useNavigate } from 'react-router-dom';

const FormFooter = ({onNextClick,back,last,loading,onClose,onBack}) => {
  const navigate=useNavigate()
  return (
    <div className={`form-footer flex justify-between px-20-scale-mob lg:px-20-scale-924 h-92-924-scale-mob lg:h-92-scale-924 items-center gap-[20px]`}>
 <button className="border border-2 border-black-primary w-217-scale-mob lg:w-217-scale-924 h-47-scale-924-mob  lg:h-47-scale-924 text-18-scale-mob lg:text-18-scale-924 font-[700] rounded-[10px] " onClick={onClose}>Close</button>

        {/* <button className="border border-2 border-black-primary w-217-scale-mob lg:w-217-scale-924 h-47-scale-924-mob lg:h-47-scale-924 text-18-scale-mob lg:text-18-scale-924 font-[700] rounded-[10px] bg-black-primary">Close</button> */}
      <div className="flex gap-[20px]">
       {back&& <button className="border border-2 border-black-primary bg-black-primary w-217-scale-mob lg:w-217-scale-924 h-47-scale-924-mob  lg:h-47-scale-924 text-18-scale-mob lg:text-18-scale-924 font-[700] rounded-[10px] text-white-100" onClick={onBack}>Back</button>
       }
        <button className="border border-2 bg-black-primary border-black-primary  w-217-scale-mob lg:w-217-scale-924 h-47-scale-924-mob  lg:h-47-scale-924 text-18-scale-mob lg:text-18-scale-924 font-[700] rounded-[10px] text-white-100" onClick={onNextClick} disabled={loading}>
          {loading?<span className="loading loading-spinner loading-sm"></span>:last?"Submit":"Next"}</button>
        
       </div> 
    </div>
  )
}

export default FormFooter