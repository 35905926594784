import React,{useState,useEffect} from 'react'
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import "./form.css"
import GlobalTextArea from '../../components/GlobalTextArea'
import ProfileIcon from '../../icons/ProfileIcon';
import FamilyIcon from '../../icons/FamilyIcon';
import FamilyInHome from '../../icons/FamilyInHome';
import GlobalTable from '../../components/Table/GlobalTable';
import CallIconSummary from '../../icons/CallIconSummary';
import MedicalConditionIcon from '../../icons/HealthCareIcon';
import PillsIcon from '../../icons/PillsIcon';
import CareIcon from '../../icons/CareIcon';
import HobbiesIcon from '../../icons/HobbiesIcon';
import { formatString } from '../../methods';
import ArtsAndCraft from '../../icons/ArtsAndCraftIcon';
import MusicIcon from '../../icons/MusicIcon';
import GardeningIcon from '../../icons/GardeningIcon';
import EventsIcon from '../../icons/EventsIcon';
import HealthCare from '../../icons/HealthCare';
import DietIcon from '../../icons/DietIcon';
import CarbsIcon from '../../icons/CarbsIcon';
import SugarIcon from '../../icons/SugarIcon';
import SaltsIcon from '../../icons/SaltsIcon';
import MealIcon from '../../icons/MealIcon';
import CusineIcon from '../../icons/CusineIcon';
import FrequencyIcon from '../../icons/FrequencyIcon';
import ListIcon from '../../icons/ListIcon';
import BedIcon from '../../icons/BedIcon';
import CycleIcon from '../../icons/CycleIcon';
import FormFooter from './FormFooter';
import { addNotes, getAllNotes, updateCallStatus } from '../../redux/actions/usersactions';
import moment from 'moment';
import KneeIcon from '../../icons/KneeIcon';
import DisabilityIcon from '../../icons/DisabilityIcon';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { usePath } from '../../states/PathContext';


const Summary = () => {
    const {path}=usePath()
    const allNotes=useSelector((state)=>state.users.notes);
    const [noteLoading,setNoteLoading]=useState(false);
    const [noteSubmited,setNoteSubmited]=useState(false)
    const [loading,setLoading]=useState(false)
    const navigate = useNavigate()
    const formData = useSelector((state) => state.users.user_details);
    const dispatch = useDispatch()
    const { id } = useParams()
    const methods = useForm({ mode: "all" });
    const { control, formState: { errors }, setValue} = methods;
    const familymemberHeadings = [{ name: "Family member name", value: "spouse_name" }, { name: "Relationship", value: "family_type" }, { name: "Age", value: "spouse_age" }]
    const contactHeadings = [{ name: "Contact name", value: "name" }, { name: "Relationship", value: "relationship" }, { name: "Contact number", value: "contact_number" }]
useEffect(()=>{
const fetchNotes=async()=>{
    try{
     await dispatch(getAllNotes(id))
    }catch(err){
        console.log(err.message)
    }
}
console.log("path",path)
fetchNotes()
},[])
    const data = {
        persnol_info: {
            "Senior unique ID": id,
            Name: formData?.full_name,
            Age: formData?.age,
            "Mobile Number": formData?.phone_number && formData.phone_number.replace(/^91/, ''),
            "Date Of birth": formData?.dob && moment(formData.dob).format("DD/MM/YYYY"),
            // Location: formData?.address,
          
        },
       

        hobbies: formData && formData.hobbies,
        diet_restrictions: formData && formData.diet_restrictions,
        meal_preference: formData && formData.meal_preferences, cuisine_preference: formData && formData.cuisine_preferences,
        exercise_details: formData && formData.exercise_details, sleep_pattern: formData && formData.sleep_pattern,
        other_hobbies: formData && formData.hobbies && formData.hobbies.find(val => val.hobby === "others"),
        social_event_and_activity: formData&&formData.hobbies&&formData.hobbies[0]?.social_events_and_activity,
        other_conditions:formData && formData.hobbies && formData.medical_conditions.find(val => val.medical_condition === "other_conditions"),
        other_surgery:formData && formData.surgical_history&& formData.surgical_history.find(val => val.surgery === "others")

    }
    useEffect(()=>{
        const noteSubmitted=allNotes&&allNotes.results.find(val=>val.tag==="onboarding");
        if(noteSubmitted){
            setNoteSubmited(true);
            setValue("notes",noteSubmitted.note)
        }
    },[allNotes])
  
    const submitNotes = async (data) => {
        setNoteLoading(true)
        try {
            await dispatch(addNotes(id, { note: data.notes, tag: "onboarding" }));
            // await dispatch(getUserDetails(id))
            setNoteSubmited(true)
            toast.success("Note added successfully")
        //    reset()
            // navigate(`/user/add/${id}/surgicalinfo/${details_id}`)
        } catch (err) {
            console.log(err)
        }
        setNoteLoading(false)
    }
//change status
const handleSubmitForm=async()=>{
    setLoading(true)
    try{
      await dispatch(updateCallStatus(id,{form_status:"completed",registration_status:"registered"}));
      toast.success("Form Submitted Successfully");
      path==="/users"? navigate(`/users`):navigate("/usermanagement")
    }catch(err){
        toast.error(err.message)
    }
    setLoading(false)
}
    return (
        <div className="pt-50-scale-mob lg:pt-40-scale-924 w-[100%] text-14-scale-mob md:text-14-scale">
            <div className="w-[80%] mx-auto  form-fields flex flex-col ">
                <div>
                    <div className="flex justify-between border-[2px] border-b-0  border-[#C6C6C6] md:h-47-scale-924 h-47-scale-mob items-center md:px-20-scale-924 px-20-scale-mob rounded-top">
                        <p className="text-16-scale-mob lg:text-16-scale-924 font-[600]">Add notes</p>
                        <button type="button"  className="md:h-30-scale-924 h-30-scale-mob w-158-scale-mob md:w-158-scale-924 border border-2 border-black-100 rounded-[10px]  text-14-scale-mob md:text-14-scale-924 bg-[#F5F5F5]" onClick={methods.handleSubmit(submitNotes)} disabled={noteSubmited}>{noteLoading?<span className="loading loading-spinner loading-sm"></span>:"Save"}</button>
                    </div>
                    <GlobalTextArea control={control} name="notes" className="!border-[#C6C6C6] rounded-bottom input-focus !h-150-scale-924" placeholder="You can record any additional information here..."
                        rules={{ required: "Please Enter note here" }} errors={errors.notes} disabled={noteSubmited}/>
                </div>
                {/* Perosonal Information */}
                <div className="bg-[#F6F6F6] rounded-[10px] pb-[5px]">
                    <div className="flex justify-between  border-b-[2px] border-[#D0D0D6]]  md:h-47-scale-924 h-47-scale-mob items-center md:px-20-scale-924 px-20-scale-mob rounded-top">
                        <p className="text-16-scale-mob lg:text-16-scale-924 font-[600]">Basic Information</p>
                        <button type="button" className="md:h-30-scale-924 h-30-scale-mob w-158-scale-mob md:w-158-scale-924 border border-2 border-black-100 rounded-[10px]  text-14-scale-mob md:text-14-scale-924 bg-[#F5F5F5]" onClick={() => navigate(`/user/add/${id}`)} >Edit details</button>

                    </div>
                    <div className="bg-[#F6F6F6] md:py-20-scale-924 py-20-scale-mob md:px-10-scale-924 px-10-scale-mob">
                        <div className="bg-white-100 rounded-[10px] md:py-20-scale-924 py-20-scale-mob md:px-10-scale-924 px-10-scale-mob flex flex-col form-fields">
                            <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob ">
                                <div className="flex items-center gap-x-[10px] md:pb-20-scale-924 pb-20-scale-mob ">
                                    <ProfileIcon />
                                    <p className='text-16-scale-mob md:text-16-scale-924 font-[600]'>Personal Information</p>

                                </div>
                                <div className="card py-20-scale-mob md:py-20-scale-924 px-20-scale-mob md:px-20-scale-924 bg-white-100">
                                    <div className="flex form-fields flex-wrap gap-x-[10px]">
                                        {Object.keys(data.persnol_info).map(val => <div className="flex flex-col gap-y-[5px] w-[20%]" key={val}>
                                            <p className="text-12-mob md:text-12-scale-924 font-[600]">{val}</p>
                                            <p className="text-12-mob md:text-12-scale-924 text-[#767676]">{data.persnol_info[val]}</p>
                                        </div>)}
                                    </div>
                                </div>
                            </div>


                            <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob ">
                                <div className="flex items-center gap-x-[15px] md:pb-20-scale-924 pb-20-scale-mob ">
                                    <FamilyIcon />
                                    <p className='text-32-scale-mob lg:md:text-16-scale-924  font-[600] pt-[2px]'>Family Information</p>


                                </div>
                                <div className="w-356-scale-mob md:w-356-scale-924 rounded-[5px] flex justify-between bg-white-100 p-10-scale-mob md:mb-10-scale mb-10-scale-mob">
                    <div>
                        <p className="text-16-scale-mob lg:text-16-scale-924 font-[700] ">Family type</p>
                        <p className="text-16-scale-mob lg:text-16-scale-924">{formData?.family_details?.family_type==="alone"?"Alone":`Living with ${formData?.family_details?.family_type||"-"}`}</p>
                    </div>
                    <div className="bg-[#D9D9D9] rounded-full md:w-51-scale-924 w-51-scale-mob md:h-51-scale-924 h-51-scale-mob flex items-center justify-center">
                    <FamilyInHome />
                    </div>
                </div>
                                {formData?.family_details?.family_type!=="alone"&&<GlobalTable headings={familymemberHeadings} data={formData?.family_details && [formData.family_details]} headingStyles={"bg-black-100 md:h-38-scale-924 h-38-scale-mob text-white-100 text-12-scale-mob md:text-12-scale-924 md:px-10-scale-924 border border-2"}
                                    dataStyles={"bg-white-100 !text-12-scale-mob md:!text-12-scale-924 md:px-10-scale-924 md:h-38-scale-924 h-38-scale-mob font-[500] border border-2"} rowStyles={"h-[38px]"}/>}
                            </div>
                            <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob ">
                                <div className="flex items-center gap-x-[15px] md:pb-20-scale-924 pb-20-scale-mob ">
                                    <CallIconSummary />
                                    <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[2px]'>Emergency contact</p>


                                </div>

                                {<GlobalTable headings={contactHeadings} data={formData ? formData.emergency_contacts : []} headingStyles={"bg-black-100 md:h-38-scale-924 h-38-scale-mob text-white-100 text-12-scale-mob md:text-12-scale-924 md:px-10-scale-924 border border-2"}
                                    dataStyles={"bg-white-100 !text-12-scale-mob md:!text-12-scale-924 md:px-10-scale-924 md:h-38-scale-924 h-38-scale-mob font-[500] border border-2"} rowStyles={"h-[38px]"}/>}
                            </div>
                        </div>


                    </div>


                </div>
                  {/* Hobbies and Lifestyle */}
                  {/* <div> */}
                    <div className="bg-[#F6F6F6] rounded-[10px] pb-[5px]">
                        <div className="bg-white-100 md:px-10-scale-924 px-10-scale-mob rounded-[5px]">
                            <p className="md:text-14-scale-924 text-14-scale-mob">{formData?.medical_info?.other_conditions}</p>
                        </div>
                        <div className="flex justify-between  border-b-[2px] border-[#D0D0D6]]  md:h-47-scale-924 h-47-scale-mob items-center md:px-20-scale-924 px-20-scale-mob rounded-top">
                            <p className="text-16-scale-mob lg:text-16-scale-924 font-[600]">Hobbies & Lifestyle</p>
                            <button type="button" className="md:h-30-scale-924 h-30-scale-mob w-158-scale-mob md:w-158-scale-924 border border-2 border-black-100 rounded-[10px]  text-14-scale-mob md:text-14-scale-924 bg-[#F5F5F5]" onClick={() => navigate(`/user/add/${id}/hobbiesinfo`)}>Edit details</button>

                        </div>



                        <div className="bg-[#F6F6F6] md:py-20-scale-924 py-20-scale-mob md:px-10-scale-924 px-10-scale-mob">
                            <div className="bg-white-100 rounded-[10px] md:py-20-scale-924 py-20-scale-mob md:px-10-scale-924 px-10-scale-mob flex flex-col form-fields">
                                <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob form-fields flex flex-col">
                                    <div className="flex items-center gap-x-[10px] md:pb-20-scale-924 pb-20-scale-mob ">
                                        <HobbiesIcon />
                                        <p className='text-16-scale-mob md:text-16-scale-924 font-[600] '>Hobbies</p>
                                    </div>

                                    <div className="flex gap-[30px] flex-wrap" >
                                        {/* {data.hobbies && data.hobbies.map(val => (

                                            <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] w-[30%] text-14-scale-mob lg:text-14-scale pb-20-scale-mob lg:pb-20-scale-924 flex flex-col gap-[10px]" key={val.condition}>

                                                {val.hobby&&formatString(val.hobby)}
                                            </div>
                                        ))} */}
                                        {data?.hobbies?.map((val, index) =>
                                            <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob lg:pb-20-scale-924 w-[30%]" key={val.id}>


                                                <div className="text-14-scale-mob md:text-14-scale-924">
                                                    <p >{val.hobby && formatString(val.hobby)}</p>

                                                </div>
                                                <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                                    {val.hobby === "arts_and_craft" ? < ArtsAndCraft /> :
                                                        val.hobby === "music" ? <MusicIcon /> : <GardeningIcon />}
                                                </div>
                                            </div>

                                        )}
                                    </div>

                                    {data.other_hobbies && <p><div className="bg-white-100 md:p-20-scale-924 p-20-scale-mob rounded-[5px]">
                                        <p className="md:text-14-scale-924 text-14-scale-mob">{data.other_hobbies.other_hobbies}</p>
                                    </div></p>}
                                    <div className="w-356-scale-mob md:w-356-scale-924 rounded-[5px] flex justify-between bg-white-100 p-20-scale-mob md:p-20-scale-924 ">
                                        <div>
                                            <p className="text-14-scale-mob lg:text-14-scale-924 font-[600] ">Social event & activity</p>
                                            <p className="text-14-scale-mob lg:text-14-scale-924">{data.social_event_and_activity||"-"}</p>
                                        </div>
                                        <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                            <EventsIcon />
                                        </div>
                                    </div>
                                </div>
                                {/* Diet */}
                                <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob form-fields flex flex-col">
                                    <div className="flex items-center gap-x-[10px]">
                                        <DietIcon />
                                        <p className='text-16-scale-mob md:text-16-scale-924 font-[600] '>Diet</p>
                                    </div>

                                    <div className="flex gap-x-[30px] flex-wrap" >

                                        {data?.diet_restrictions?.map((val, index) =>
                                            <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob lg:pb-20-scale-924 w-[30%]" key={val.id}>


                                                <div className="text-14-scale-mob md:text-14-scale-924">
                                                    <p >No/{val.restriction && formatString(val.restriction)}</p>

                                                </div>
                                                <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                                     {val.restriction === "less_carbs" ? <CarbsIcon /> :
                                                        val.restriction === "less_sugar" ? <SugarIcon /> : <SaltsIcon />}
                                                </div>
                                            </div>

                                        )}
                                    </div>
                                    {formData&&formData.elergy && <p><div className="bg-white-100 md:p-20-scale-924 p-20-scale-mob rounded-[5px]">
                                        <p className="md:text-14-scale-924 text-14-scale-mob">{formData.elergy }</p>
                                    </div></p>}
                                    <div className="flex gap-[30px] ">


                                        <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob lg:pb-20-scale-924 w-[40%]" >

                                            <div className="text-14-scale-mob md:text-14-scale-924">
                                                <p className="font-[600]">Meal Preference</p>
                                                <p >{(data.meal_preference && formatString(data.meal_preference.preference))||"-"}</p>

                                            </div>
                                            <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                                <MealIcon />
                                            </div>
                                        </div>



                                        <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob lg:pb-20-scale-924 w-[40%]" >

                                            <div className="text-14-scale-mob md:text-14-scale-924 ">
                                                <p className="font-[600]">Cuisine Preference</p>
                                                <p >{(data && data.cuisine_preference && formatString(data.cuisine_preference.cuisine))||"-"}</p>

                                            </div>
                                            <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                                <CusineIcon />
                                            </div>
                                        </div>



                                    </div>


                                </div>
                                {/* Exercise */}
                                <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob form-fields flex flex-col">
                                    <div className="flex items-center gap-x-[10px]">
                                        <HealthCare />
                                        <p className='text-16-scale-mob md:text-16-scale-924 font-[600] '>Exercise</p>
                                    </div>



                                    <div className='flex gap-[30px]'>
                                        <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob lg:pb-20-scale-924 w-[40%]" >


                                            <div className="text-14-scale-mob md:text-14-scale-924">
                                                <p className="font-[600]">How often do you exercise</p>
                                                <p >{data?.exercise_details?.frequency||"-"}</p>

                                            </div>
                                            <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                                <FrequencyIcon />
                                            </div>
                                        </div>
                                        <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob lg:pb-20-scale-924 w-[40%]" >


                                            <div className="text-14-scale-mob md:text-14-scale-924">
                                                <p className="font-[600]">Types of exercise</p>
                                                {/* <p >{data?.exercise_details?.type_of_exercise?.map(val => val + ",")||"-"}</p> */}
                                                {data?.exercise_details?.type_of_exercise_details?.length>0? <p>  {data?.exercise_details?.type_of_exercise?.map((val, index) =>
                                        index + 1 !== data?.exercise_details?.type_of_exercise.length
                                            ? `${val}, `
                                            : val
                                    ) || "-"}</p>:<p>-</p>}

                                            </div>
                                            <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                                <ListIcon />
                                            </div>
                                        </div>
                                    </div>





                                </div>


                                {/* sleep routine */}
                                <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob form-fields flex flex-col">
                                    <div className="flex items-center gap-x-[10px]">
                                        <BedIcon />
                                        <p className='text-16-scale-mob md:text-16-scale-924 font-[600] '>Sleep routine</p>
                                    </div>



                                    <div className='flex gap-[30px]'>
                                        <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob lg:pb-20-scale-924 w-[40%]" >


                                            <div className="text-14-scale-mob md:text-14-scale-924">
                                                <p className="font-[600]">Sleep pattren</p>
                                                <p >{data?.sleep_pattern && formatString(data.sleep_pattern.pattern)||"-"}</p>

                                            </div>
                                            <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                                <BedIcon />
                                            </div>
                                        </div>
                                        <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob lg:pb-20-scale-924 w-[40%]" >


                                            <div className="text-14-scale-mob md:text-14-scale-924">
                                                <p className="font-[600]">Sleep duration</p>
                                                <p >{data?.sleep_pattern?.duration||"-"}</p>

                                            </div>
                                            <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                                <CycleIcon />
                                            </div>
                                        </div>
                                    </div>





                                </div>
                            </div>


                        </div>


                    </div>
                {/* </div> */}
                {/* Medical Information */}
                <div className="bg-[#F6F6F6] rounded-[10px] pb-[5px]">
                    <div className="flex justify-between  border-b-[2px] border-[#D0D0D6]]  md:h-47-scale-924 h-47-scale-mob items-center md:px-20-scale-924 px-20-scale-mob rounded-top">
                        <p className="text-16-scale-mob lg:text-16-scale-924 font-[600]">Medical history</p>
                        <button type="button" className="md:h-30-scale-924 h-30-scale-mob w-158-scale-mob md:w-158-scale-924 border border-2 border-black-100 rounded-[10px]  text-14-scale-mob md:text-14-scale-924 bg-[#F5F5F5]" onClick={() => navigate(`/user/add/${id}/medicalinfo`)} >Edit details</button>

                    </div>
                    <div className="bg-[#F6F6F6] md:py-10-scale-924 py-10-scale-mob md:px-10-scale-924 px-10-scale-mob ">
                        <div className="bg-[#F6F6F6] md:py-20-scale-924 py-20-scale-mob md:px-10-scale-924 px-10-scale-mob flex flex-col form-fields ">
                            <div className="bg-white-100 rounded-[10px] md:py-20-scale-924 py-20-scale-mob md:px-10-scale-924 px-10-scale-mob flex flex-col ">
                                <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob flex flex-col ">
                                    <div>
                                        <div className="flex items-center gap-x-[10px] md:pb-20-scale-924 pb-20-scale-mob ">
                                            <MedicalConditionIcon />
                                            <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[10px]'>Medical condition</p>

                                        </div>
                                        <div className="flex flex-wrap form-fields">
                                            {formData && formData.medical_conditions?.map(val => (
                                                <div className="w-[30%] bg-white-100 md:p-20-scale p-20-scale flex flex-col gap-y-[20px] rounded-[5px]" key={val.medical_condition}>
                                                    {/* <p>hello</p>  */}
                                                    <p className="md:text-14-scale-924 text-14-scale-mob font-[600]">{val.medical_condition && formatString(val.medical_condition)}</p>
                                                    <p className="md:text-14-scale-924 text-14-scale-mob">{val["year"]?`${val["year"]} years`:"-"}</p>
                                                </div>

                                            ))}


                                        </div>
                                    </div>
                                    {data?.other_conditions&& <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px]">
                                        <p className="md:text-14-scale-924 text-14-scale-mob">{data?.other_conditions?.other_diseases||"-"}</p>
                                    </div>}
                                </div>
                            </div>
                            <div className="bg-white-100 rounded-[10px] md:py-20-scale-924 py-20-scale-mob md:px-10-scale-924 px-10-scale-mob flex flex-col  form-fields ">
                                <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-0-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob flex flex-col form-gap-20">

                                    <div className="flex items-center gap-x-[10px] md:pb-20-scale-924 pb-20-scale-mob ">
                                        <PillsIcon />
                                        <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[10px]'>Medication</p>

                                    </div>

                                    {formData && formData.medications && formData.medications.map(val => (
                                     (val.condition|| val.medicine_name)&&  <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] flex justify-between" key={val.condition}>
                                            <div>
                                                <p className="md:text-14-scale-924 text-14-scale-mob font-[700]">{val.condition && formatString(val.condition)}</p>
                                                <p className="md:text-14-scale-924 text-14-scale-mob ">{val.medicine_name}</p>
                                            </div>
                                            <div className="bg-black-100 md:py-10-scale-924 py-10-scale-mob  md:px-30-scale-924 px-30-scale-mob rounded-[10px]">
                                                <p className="text-white-100 md:text-14-scale-924 text-14-scale-mob font-[600]">Dosage Frequency</p>
                                                <p className="text-white-100 md:text-14-scale-924 text-14-scale-mob ">{val.dosage_frequency}</p>
                                            </div>
                                        </div>))}
                                </div>
                            </div>
                            <div className="bg-white-100 rounded-[10px] md:py-20-scale-924 py-20-scale-mob md:px-10-scale-924 px-10-scale-mob flex flex-col ">
                                <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob flex flex-col form-gap-20">

                                    <div className="flex items-center gap-x-[10px] md:pb-20-scale-924 pb-20-scale-mob ">
                                        <CareIcon />
                                        <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[10px]'>Professional caregiver team</p>

                                    </div>

                                    {/* {data.caretaker && data.caretaker.map(val => (
                                        <div className="flex justify-between flex-wrap" >
                                             <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] w-[48%] text-14-scale-mob lg:text-14-scale pb-20-scale-mob lg:pb-20-scale-924 flex flex-col gap-[10px]" >
                                                <p className="font-[600]">Knee replacement</p>
                                                <p>{val["Expert type"]}</p>
                                                {data?.surgicalHistory?.map((val, index) => <div className="flex justify-between key={val}"><p >{index + 1} {val}</p><p> {`surgery done before  ${data.surgical_info[val] && data.surgical_info[val]} years`}</p></div>)}

                                            </div>
                                            <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] w-[30%] text-14-scale-mob lg:text-14-scale pb-20-scale-mob lg:pb-20-scale-924 flex flex-col gap-[10px]" key={val.condition}>
                                            </div>
                                        </div>))} */}
                                    {formData && formData.professional_caregiver_team && formData.professional_caregiver_team.map(val => (
                                     (val.expert_name||val.expert_type||val.expert_name)&&  <div className="flex justify-between flex-wrap gap-y-[15px]"  key={val.expert_name} >
                                            <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px]  text-14-scale-mob lg:text-14-scale pb-20-scale-mob lg:pb-20-scale-924 flex flex-col gap-[10px] w-[22%]" >
                                                <p className="text-14-scale-mob md:text-14-scale-924 font-[700]">Expert type</p>
                                                <p className="text-14-scale-mob md:text-14-scale-924">{val["expert_type"]?formatString(val["expert_type"]):"-"}</p>
                                            </div>
                                            <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px]  text-14-scale-mob lg:text-14-scale pb-20-scale-mob lg:pb-20-scale-924 flex flex-col gap-[10px] w-[22%]" >
                                                <p className="text-14-scale-mob md:text-14-scale-924 font-[700]">Expert name</p>
                                                <p className="text-14-scale-mob md:text-14-scale-924">{val["expert_name"]||"-"}</p>
                                            </div>
                                            <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px]  text-14-scale-mob lg:text-14-scale pb-20-scale-mob lg:pb-20-scale-924 flex flex-col gap-[10px] w-[22%]" >
                                                <p className="text-14-scale-mob md:text-14-scale-924 font-[700]">Expertise</p>
                                                <p className="text-14-scale-mob md:text-14-scale-924">{val["expertise"]||"-"}</p>
                                            </div>
                                            <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px]  text-14-scale-mob lg:text-14-scale pb-20-scale-mob lg:pb-20-scale-924 flex flex-col gap-[10px] w-[22%]" >
                              <p className="text-14-scale-mob md:text-14-scale-924 font-[700]">Expert Number</p>
                                <p className="text-14-scale-mob md:text-14-scale-924">{val["phone_number"]||"-"}</p>
                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
                {/* Surgical info */}
                <div>
                    <div className="bg-[#F6F6F6] rounded-[10px] pb-[5px]">

                        <div className="flex justify-between  border-b-[2px] border-[#D0D0D6]]  md:h-47-scale-924 h-47-scale-mob items-center md:px-20-scale-924 px-20-scale-mob rounded-top">
                            <p className="text-16-scale-mob lg:text-16-scale-924 font-[600]">Surgical history & disabilities</p>
                            <button type="button" className="md:h-30-scale-924 h-30-scale-mob w-158-scale-mob md:w-158-scale-924 border border-2 border-black-100 rounded-[10px]  text-14-scale-mob md:text-14-scale-924 bg-[#F5F5F5]" onClick={() => navigate(`/user/add/${id}/surgicalinfo`)}>Edit details</button>

                        </div>
                        <div className="card py-20-scale-mob md:py-20-scale-924 px-20-scale-mob md:px-20-scale-924 ">
                            <div className="flex form-fields justify-between w-[100%] ">

                                {/* <p className="font-[600]">Knee replacement</p> */}
                                {/* <p>{val["Expert type"]}</p> */}
                                <div className=" w-[48%] text-14-scale-mob lg:text-14-scale  flex flex-col gap-[20px] " >

                                    {formData?.surgical_history?.map((val, index) => <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob lg:pb-20-scale-924" key={val}>


                                        <div className="text-14-scale-mob md:text-14-scale-924">
                                            <p className="font-[600]">{val.surgery && formatString(val.surgery)}</p>
                                            <p> {val.year?`surgery done before ${val.year} years`:"-"}</p>
                                        </div>
                                        <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                            <KneeIcon />
                                        </div>
                                    </div>

                                    )}
                                </div>
                               
                                <div className="w-[48%] text-14-scale-mob lg:text-14-scale  flex flex-col gap-[20px]" >

                                    {formData?.physical_disabilities?.map((val, index) => <div className="flex justify-between bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob lg:pb-20-scale-924 items-center " key={val}>


                                        <div className="text-14-scale-mob md:text-14-scale-924">
                                            <p className="font-[600]">Physical disability</p>
                                            <p>{val.disability && formatString(val.disability)}</p>

                                        </div>
                                        <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                            <DisabilityIcon />
                                        </div>
                                    </div>

                                    )}
                                </div>

                            </div>

                            {data.other_surgery && <p><div className="bg-white-100 md:p-20-scale-924 p-20-scale-mob rounded-[5px]">
                                        <p className="md:text-14-scale-924 text-14-scale-mob">{data.other_surgery.other_details}</p>
                                    </div></p>}
                        </div>


                    </div>
                </div>

              
            </div>
            <div className="pt-150-scale-mob lg:pt-100-scale-924">
                <FormFooter back={true} last={true} onBack={()=>navigate(-1)} onNextClick={handleSubmitForm} loading={loading} onClose={()=>{ path==="/users"? navigate(`/users`):navigate("/usermanagement")}}/>
            </div>
        </div>
    )
}

export default Summary