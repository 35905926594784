import React,{useState,forwardRef} from 'react'
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../icons/CalendarIcon';


const GlobalDatePicker = ({date,handleDateChange,handleCalendarClose}) => {
    // const [startDate, setStartDate] = useState(new Date());
    const ExampleCustomInput = forwardRef(
      ({ value, onClick, className }, ref) => (
        <button className={className} onClick={onClick} ref={ref}>
      {/* <CalendarIcon/> */}
      {/* {value} */}
      <CalendarIcon className={"!w-20 !h-20"}/>
        </button>
      ),
    );
    // console.log(startDate,"startDatestartDate")
    return (
        
        <DatePicker
        showTimeSelect
        popperPlacement="bottom" // Ensures the calendar opens in the correct position
      portalId="root-portal" 
          selected={date}
          onChange={(date) => handleDateChange(date)}
          customInput={<ExampleCustomInput className="example-custom-input" />}
          dateFormat="d/MM/YYYY h:mm aa"
          onCalendarClose={handleCalendarClose}
        />
      
      );
}

export default GlobalDatePicker