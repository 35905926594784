import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

const GlobalInput = forwardRef(({ name, label, rules, control, className, errors, defaultValue = '', onChange,type, ...props }, ref) => {
  return (
    control && (
      <div>
        {label&&<div className="pb-[5px] ">
          <label className={props.labelStyles}>{label}{props.required&&<span className="text-[#FF4664] pl-[5px]">*</span>}</label>
        </div>}
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div>
            <div className="relative">
            {props.phone_number&&(

            <p className="absolute inset-y-0 left-2  flex items-center pr-[15px]  text-16-scale-mob md:text-16-scale-924 font-600 border-r-2 border-black-100 font-[600]">
            + 91
          </p>
          )
            }
              <input
              // value={field.value||""}
              min={type==="number"&&0}
              max={props.max}
                type={type||"text"}
                {...field}
                {...props}
                ref={(e) => {
                  field.ref(e);
                  if (ref) {
                    ref.current = e;
                  }
                }}
                placeholder={props.placeholder}
                className={`border border-[2px] ${errors?"border-[red] !border-[2px]":"border-[#747474]"} h-[45px] w-[100%] rounded-[4px] text-[black] ${props.phone_number?"pl-[70px]":props.search?"pl-[40px]":"pl-[10px]"} text-16-scale-mob md:text-16-scale-924 ${className}`}
                onChange={(e) => {
                  field.onChange(e); // Update the field value in the form
                  if (onChange) {
                    onChange(e); // Call the custom onChange function
                  }
                }}
              />
              </div>
              {errors && <span style={{ color: 'red' }}>{errors.message}</span>}
            </div>
          )}
        />
      </div>
    )
  );
});

export default GlobalInput;