import React from "react";

function AddFillIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
    //   width="18"
    //   height="18"
      fill="none"
      viewBox="0 0 18 18"
    className="w-18-scale-mob lg:w-18-scale-924 h-18-scale-mob lg:h-18-scale-924"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M4.345 1.017a42.253 42.253 0 019.31 0c1.713.192 3.095 1.541 3.296 3.26a40.659 40.659 0 010 9.446c-.201 1.719-1.583 3.068-3.296 3.26a42.246 42.246 0 01-9.31 0c-1.713-.192-3.095-1.541-3.296-3.26a40.653 40.653 0 010-9.445 3.734 3.734 0 013.295-3.26M9 4.007a.75.75 0 01.75.75V8.25h3.493a.75.75 0 110 1.5H9.75v3.493a.75.75 0 01-1.5 0V9.75H4.757a.75.75 0 010-1.5H8.25V4.757a.75.75 0 01.75-.75z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default AddFillIcon;