import { combineReducers, createStore, applyMiddleware } from "redux";
import {thunk} from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import { FormReducer } from "./reducer/FormReducer";
import { usersReducer } from "./reducer/usersReducer";
import { commonReducers } from "./reducer/commonReducers";


const reducer = combineReducers({
 formDetails:FormReducer,
 users:usersReducer,
 common:commonReducers
});

const composeEnhancers = composeWithDevTools({
 
});
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
export default store;

