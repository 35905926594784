
import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

const GlobalTextArea = forwardRef(({ name, label, rules, control, className, errors, defaultValue = '', onChange, ...props }, ref) => {
  return (
    control && (
      <div>
       {label&& <div className="pb-[5px] pl-[5px]">
          <label className={props.labelStyles}>{label}{props.required&&<span className="text-[#FF4664] pl-[5px]">*</span>}</label>
        </div>}
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <>
              <textarea
              readOnly={props.disabled}
                {...field}
                {...props}
                ref={(e) => {
                  field.ref(e);
                  if (ref) {
                    ref.current = e;
                  }
                }}
                placeholder={props.placeholder}
                className={`border border-[2px] ${errors?"border-[red]":"border-[#747474]"} h-131-scale w-[100%] rounded-[4px] pl-[10px] text-16-scale-mob md:text-16-scale-924 text-[black]  ${className}`}
                onChange={(e) => {
                  field.onChange(e); // Update the field value in the form
                  if (onChange) {
                    onChange(e); // Call the custom onChange function
                  }
                }}
              />
              {errors && <span style={{ color: 'red' }}>{errors.message}</span>}
            </>
          )}
        />
      </div>
    )
  );
});

export default GlobalTextArea;

