// import React from "react";

// function AddIcon() {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="25"
//       height="24"
//       fill="none"
//       viewBox="0 0 25 24"
//     >
//       <path
//         fill="#000"
//         d="M7.746 12a.75.75 0 01.75-.75h3.493V7.759a.75.75 0 111.5 0v3.493h3.493a.75.75 0 110 1.5H13.49v3.493a.75.75 0 11-1.5 0V12.75H8.496a.75.75 0 01-.75-.75z"
//       ></path>
//       <path
//         fill="#000"
//         fillRule="evenodd"
//         d="M8.055 3.769a42.502 42.502 0 019.366 0c1.827.204 3.302 1.643 3.516 3.48.37 3.157.37 6.346 0 9.503-.215 1.837-1.69 3.274-3.516 3.48a42.495 42.495 0 01-9.366 0c-1.827-.206-3.302-1.643-3.516-3.48a40.902 40.902 0 010-9.503c.214-1.837 1.69-3.276 3.516-3.48zm9.2 1.49a41 41 0 00-9.034 0 2.486 2.486 0 00-2.193 2.165 39.4 39.4 0 000 9.154A2.486 2.486 0 008.22 18.74c2.977.332 6.057.332 9.034 0a2.485 2.485 0 002.192-2.163c.355-3.041.355-6.114 0-9.154a2.485 2.485 0 00-2.192-2.163"
//         clipRule="evenodd"
//       ></path>
//     </svg>
//   );
// }

// export default AddIcon;


// import React from "react";

// function Icon() {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="20"
//       height="20"
//       viewBox="0 0 1000 1000"
//       style={{
//         backgroundColor: "white", // Set the background color to white
//         fill: "black", // Set the icon color to black
//       }}
//       fill= "white" 
//     >
//       <path d="M856 40H142q-42 0-72 30t-30 72v714q0 42 30 72t72 30h714q42 0 72-30t30-72V142q0-42-30-72t-72-30zM754 550H550v204H448V550H244V448h204V244h102v204h204v102z"></path>
//     </svg>
//   );
// }

// export default Icon;


import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 48 48"
    >
      <path fill="#fff" fillOpacity="0.01" d="M0 0H48V48H0z"></path>
      <rect
        width="36"
        height="36"
        x="6"
        y="6"
        fill="#fff"
        stroke="#000"
        strokeLinejoin="round"
        strokeWidth="4"
        rx="3"
      ></rect>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M24 16v16M16 24h16"
      ></path>
    </svg>
  );
}

export default Icon;