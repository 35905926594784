import React from 'react'
import FamilyIcon from '../../../icons/FamilyIcon'
import FamilyInHome from '../../../icons/FamilyInHome'
import GlobalTable from '../../../components/Table/GlobalTable';
//redux
import { useSelector } from 'react-redux';

import CallIconSummary from '../../../icons/CallIconSummary';
import SideNav from './SideNav';
import { useNavigate,useParams } from 'react-router-dom';


const Generalinfo = () => {
const navigate=useNavigate();
const params=useParams()
const {id}=params;
    const familymemberHeadings = [{ name: "Family member name", value: "spouse_name" }, { name: "Relationship", value: "family_type" }, { name: "Age", value: "spouse_age" }]
    const contactHeadings = [{ name: "Contact name", value: "name" }, { name: "Relationship", value: "relationship" }, { name: "Contact number", value: "contact_number" }]
    const formData = useSelector((state) => state.users.user_details);

    return (
        <div className="flex justify-between">
            <div className='w-[20%]'>
            <SideNav  />
            </div>
        <div className='w-[75%] flex flex-col form-fields md:pb-20-scale-924 pb-20-scale-mob'>
            <div className="flex justify-end">
                    <button type="button"  onClick={()=>navigate(`/user/add/${id}`)} className="md:h-30-scale-924 h-30-scale-mob w-158-scale-mob md:w-158-scale-924 border border-1 border-black-100 rounded-[6px]  text-14-scale-mob md:text-14-scale-924 bg-[#F5F5F5]">Edit details</button>
            </div>
            <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob ">
                <div className="flex items-center gap-x-[15px] md:pb-10-scale-924 pb-10-scale-mob ">
                    <FamilyIcon />
                    <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[2px]'>Family Information</p>
                </div>
                <div className="w-356-scale-mob md:w-356-scale-924 rounded-[5px] flex justify-between bg-white-100 p-10-scale-mob md:mb-10-scale mb-10-scale-mob">
                    <div>
                        <p className="text-16-scale-mob lg:text-16-scale-924 font-[700] ">Family type</p>
                        <p className="text-16-scale-mob lg:text-16-scale-924">{formData?.family_details?.family_type==="alone"?"Alone":`Living with ${formData?.family_details?.family_type||"-"}`}</p>
                    </div>
                    <div className="bg-[#D9D9D9] rounded-full md:w-51-scale-924 w-51-scale-mob md:h-51-scale-924 h-51-scale-mob flex items-center justify-center">
                    <FamilyInHome />
                    </div>
                </div>
                {formData?.family_details?.family_type!=="alone"&&<GlobalTable headings={familymemberHeadings} data={formData?.family_details && [formData.family_details]} headingStyles={"bg-black-100 md:h-38-scale-924 h-38-scale-mob text-white-100 text-12-scale-mob md:text-12-scale-924 md:px-10-scale-924 border border-2"}
                    dataStyles={"bg-white-100 !text-12-scale-mob md:!text-12-scale-924 md:px-10-scale-924 md:h-38-scale-924 h-38-scale-mob font-[500] border border-2"} rowStyles={"!h-[38px]"}/>}
            </div>
            <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob ">
                <div className="flex items-center gap-x-[15px] md:pb-20-scale-924 pb-20-scale-mob ">
                    <CallIconSummary />
                    <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[2px]'>Emergency contact</p>
                </div>

                {<GlobalTable headings={contactHeadings} data={formData ? formData.emergency_contacts : []} headingStyles={"bg-black-100 md:h-38-scale-924 h-38-scale-mob text-white-100 text-12-scale-mob md:text-12-scale-924 md:px-10-scale-924 border border-2"}
                    dataStyles={"bg-white-100 !text-12-scale-mob md:!text-12-scale-924 md:px-10-scale-924 md:h-38-scale-924 h-38-scale-mob font-[500] border border-2"} rowStyles={"!h-[38px]"}/>}
            </div>

        </div>
        </div>
    )
}

export default Generalinfo