import React from "react";

function NextPageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="9"
      fill="none"
      viewBox="0 0 6 9"
    >
      <path
        stroke="#00182E"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1 8.5l4-4-4-4"
      ></path>
    </svg>
  );
}

export default NextPageIcon;