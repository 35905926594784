import React from "react";

function EyeIcon({className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
    //   width="24"
    //   height="24"
      fill="none"
      viewBox="0 0 24 24"
      className={`w-24-scale-mob md:w-24-scale-924 h-24-scale-mob md:h-24-scale-924 ${className}`}


    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M3 13c3.6-8 14.4-8 18 0M12 17a3 3 0 110-6 3 3 0 010 6z"
      ></path>
    </svg>
  );
}

export default EyeIcon;