import * as events from "../Events";
const initialState = {
  basic_info: null,
  medical_info:null,
  surgical_info:null,
  hobbies_info:null,


};
export const FormReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.BASIC_INFO:
      return {
        ...state,
        basic_info:action.payload
      };
      case events.MEDICAL_INFO:
        return {
          ...state,
          medical_info:action.payload
        };
        case events.SURGICAL_INFO:
      return {
        ...state,
        surgical_info:action.payload
      };
      case events.HOBBIES_INFO:
      return {
        ...state,
        hobbies_info:action.payload
      };
    
    default:
      return state;
  }
};
