import moment from 'moment';
export function formatString(str) {
    // Replace underscores with spaces
    if (!str) {
      return "";
    }
    const replacedStr = str.replace(/_/g, ' ').replace(/and/,"&");

    // Capitalize the first letter of each word
    const capitalizedStr = replacedStr.replace(/\b\w/g, char => char.toUpperCase());
    
    return capitalizedStr;
  }


  export const compareTime = (dateString) => {
    const givenDate = moment(dateString); // The provided date
    const now = moment(); // Current date and time
    
    // Calculate the absolute difference in hours between the given date and the current time
    const diffInHours = Math.abs(givenDate.diff(now, 'hours', true)); // 'true' to get fractional hours
  
    if (diffInHours < 48) {
      return "Less than 48 hours";
    } else {
      return "Greater than 48 hours";
    }
  };
  
  