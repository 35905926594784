import React from "react";


function CalendarIcon({className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="18"
      // height="20"
      fill="none"
      viewBox="0 0 18 20"
      className={`w-18-scale-mob lg:w-18-scale-924 h-20-scale-mob lg:h-20-scale-924 ${className}`}
      // className={className}
    >
      <path
        fill="#111827"
        d="M2.308 19.5c-.505 0-.933-.175-1.283-.525A1.745 1.745 0 01.5 17.692V4.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h1.384V1.154c0-.22.074-.402.22-.55a.746.746 0 01.55-.22c.219 0 .402.074.549.22.146.148.22.33.22.55V2.5h7.577V1.135c0-.213.072-.391.215-.535a.727.727 0 01.535-.215c.213 0 .39.072.534.215a.726.726 0 01.216.535V2.5h1.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v13.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H2.308zm0-1.5h13.384a.294.294 0 00.212-.096.294.294 0 00.096-.212V8.308H2v9.384c0 .077.032.148.096.212a.294.294 0 00.212.096zM2 6.808h14v-2.5a.294.294 0 00-.096-.212.294.294 0 00-.212-.096H2.308a.294.294 0 00-.212.096.294.294 0 00-.096.212v2.5zm7 5.269a.853.853 0 01-.626-.259.853.853 0 01-.258-.626c0-.245.086-.453.258-.626A.853.853 0 019 10.308c.245 0 .454.086.626.258a.853.853 0 01.258.626.853.853 0 01-.258.626.853.853 0 01-.626.259zm-4 0a.853.853 0 01-.626-.259.853.853 0 01-.258-.626c0-.245.086-.453.258-.626A.853.853 0 015 10.308c.245 0 .454.086.626.258a.853.853 0 01.258.626.853.853 0 01-.258.626.853.853 0 01-.626.259zm8 0a.853.853 0 01-.626-.259.853.853 0 01-.258-.626c0-.245.086-.453.258-.626a.853.853 0 01.626-.258c.245 0 .454.086.626.258a.853.853 0 01.258.626.853.853 0 01-.258.626.853.853 0 01-.626.259zM9 16a.853.853 0 01-.626-.259.853.853 0 01-.258-.625c0-.245.086-.454.258-.627A.853.853 0 019 14.232c.245 0 .454.086.626.258a.853.853 0 01.258.627.853.853 0 01-.258.625A.853.853 0 019 16zm-4 0a.853.853 0 01-.626-.259.853.853 0 01-.258-.625c0-.245.086-.454.258-.627A.853.853 0 015 14.232c.245 0 .454.086.626.258a.853.853 0 01.258.627.853.853 0 01-.258.625A.853.853 0 015 16zm8 0a.853.853 0 01-.626-.259.853.853 0 01-.258-.625c0-.245.086-.454.258-.627a.853.853 0 01.626-.258c.245 0 .454.086.626.258a.853.853 0 01.258.627.853.853 0 01-.258.625A.853.853 0 0113 16z"
      ></path>
    </svg>
  );
}

export default CalendarIcon;