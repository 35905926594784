import React from 'react'
import "./stepper.css";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Stepper = () => {
  const formData = useSelector((state) => state.users.user_details);
  const location=useLocation();
  return (
    <div className='flex w-[90%] justify-center'>
        <ul className="steps steps-vertical md:steps-horizontal pt-10-scale-924 w-[99%] ">
            <li data-content={formData?.family_details?"✓":"1"} className="step !step-neutral text-14-scale-mob lg:text-14-scale-924 ">Basic Information</li>
            <li data-content={formData&&formData.hobbies&&formData.hobbies.length?"✓":"2"} className={`step text-14-scale-mob lg:text-14-scale-924 ${location.pathname.includes("hobbiesinfo")||location.pathname.includes("medicalinfo")||location.pathname.includes("surgicalinfo")||location.pathname.includes("hobbies")||location.pathname.includes("summary")?"!step-neutral":""}`}>Hobbies & Lifestyle</li>

            <li data-content={formData&&formData.medications&&formData.medications.length?"✓":"3"} className={`step  text-14-scale-mob lg:text-14-scale-924 ${location.pathname.includes("medicalinfo")||location.pathname.includes("surgicalinfo")||location.pathname.includes("summary")?"!step-neutral":""}`}>Medical history</li>
            <li data-content={formData&&formData.physical_disabilities&&formData.physical_disabilities.length?"✓":"4"} className={`step  text-14-scale-mob lg:text-14-scale-924 ${location.pathname.includes("surgicalinfo")||location.pathname.includes("summary")?"!step-neutral":""}`}>Surgical history  & disabilities</li>
           <li data-content={formData&&formData.call_status==="completed"?"✓":"5"} className={`step text-14-scale-mob lg:text-14-scale-924 ${location.pathname.includes("summary")?"!step-neutral":""}`}>Summary</li>
        </ul>
  </div>
  )
}

export default Stepper