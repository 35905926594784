import React from 'react'
import SurgicalIcon from '../../../icons/SurgicalIcon';
//redux
import { useSelector } from 'react-redux';
import { formatString } from '../../../methods';
import KneeIcon from '../../../icons/KneeIcon';
import DisabilityIcon from '../../../icons/DisabilityIcon';
import SideNav from './SideNav';
import { useNavigate,useParams } from 'react-router-dom';

const Surgicalinfo = () => {
    const formData = useSelector((state) => state.users.user_details);
    const navigate=useNavigate();
    const params=useParams();
    const {id}=params;
    const other_surgery= formData && formData.surgical_history&& formData.surgical_history.find(val => val.surgery === "others")

    return (
        <div className="flex justify-between">
        <div className='w-[20%]'>
        <SideNav />
        </div>
    <div className='w-[77%]'>
             <div className="flex justify-end">
                <button type="button" className="md:h-30-scale-924 h-30-scale-mob w-158-scale-mob md:w-158-scale-924 border border-1 border-black-100 rounded-[6px]  text-14-scale-mob md:text-14-scale-924 bg-[#F5F5F5]" onClick={()=>navigate(`/user/add/${id}/surgicalinfo`)} >Edit details</button>
            </div>
            <div className="bg-white-100 rounded-[10px] md:py-20-scale-924 py-20-scale-mob md:px-10-scale-924 px-10-scale-mob flex flex-col ">
                <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob ">
                    <div className="flex items-center gap-x-[10px]  ">
                        <SurgicalIcon />
                        {/* <p className='text-16-scale-mob md:16-scale-924 font-[600]'>Surgical history & disabilities</p> */}
                        <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[10px]'>Surgical history & disabilities</p>

                    </div>
                    <div className="card py-20-scale-mob md:py-20-scale-924 px-20-scale-mob md:px-20-scale-924  ">
                        <div className="flex form-fields justify-between w-[100%] ">

                            {/* <p className="font-[600]">Knee replacement</p> */}
                            {/* <p>{val["Expert type"]}</p> */}
                            <div className=" w-[48%] text-14-scale-mob lg:text-14-scale  flex flex-col gap-[20px] " >

                                {formData?.surgical_history?.map((val, index) => <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob lg:pb-20-scale-924" key={val}>


                                    <div className="text-14-scale-mob md:text-14-scale-924">
                                        <p className="font-[600]">{val.surgery && formatString(val.surgery)}</p>
                                        <p> {val.year?`surgery done before ${val.year} years`:"-"}</p>
                                    </div>
                                    {/* p-10-scale-mob md:p-10-scale-924 */}
                                    <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                        <KneeIcon />
                                    </div>
                                </div>

                                )}
                            </div>

                            <div className="w-[48%] text-14-scale-mob lg:text-14-scale  flex flex-col gap-[20px]" >

                                {formData?.physical_disabilities?.map((val, index) => <div className="flex justify-between bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob lg:pb-20-scale-924 items-center " key={val}>


                                    <div className="text-14-scale-mob md:text-14-scale-924">
                                        <p className="font-[600]">Physical disability</p>
                                        <p>{val.disability && formatString(val.disability)}</p>

                                    </div>
                                    <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                        <DisabilityIcon/>
                                    </div>
                                </div>

                                )}
                            </div>


                           

                        </div>
                        {other_surgery && <p><div className="bg-white-100 md:p-20-scale-924 p-20-scale-mob rounded-[5px]">
                                        <p className="md:text-14-scale-924 text-14-scale-mob">{other_surgery.other_details}</p>
                                    </div></p>}
                    </div>
                </div>



            </div>



</div>

        </div>

    )
}

export default Surgicalinfo