import React from "react";

function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
    //   width="18"
    //   height="18"
      fill="none"
      viewBox="0 0 18 18"
      className="md:w-18-scale-924 md:h-18-scale-924 w-18-scale-mob h-18-scale-mob"
    >
      <path
        fill="#000"
        d="M14.027 12.847l3.57 3.568-1.18 1.18-3.568-3.57a7.467 7.467 0 01-4.681 1.641c-4.14 0-7.5-3.36-7.5-7.5 0-4.14 3.36-7.5 7.5-7.5 4.14 0 7.5 3.36 7.5 7.5a7.467 7.467 0 01-1.64 4.68zm-1.671-.618A5.817 5.817 0 0014 8.166a5.832 5.832 0 00-5.833-5.833 5.832 5.832 0 00-5.833 5.833 5.832 5.832 0 005.833 5.833 5.816 5.816 0 004.063-1.646l.124-.124z"
      ></path>
    </svg>
  );
}

export default SearchIcon;