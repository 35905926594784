import React,{useEffect} from 'react'
import SideNav from '../SideNav'
import MedicalConditionIcon from '../../../../icons/HealthCareIcon'
// import "../users.css"
import "./aiinsights.css"
import PillsIcon from '../../../../icons/PillsIcon';
//redux
import { useSelector } from 'react-redux';

const Health = () => {
  const ainsights = useSelector((state) => state.common.aiinsights);
  

  return (
    <div className="flex justify-between">
      <div className='w-[20%]'>
      <SideNav  aisights={true}/>
      </div>
          <div className="w-[77%] flex flex-col form-fields">
                      <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob flex flex-col form-gap-20">
                          
                              <div className="flex items-center gap-x-[10px]  ">
                                  <MedicalConditionIcon />
                                  <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[10px]'>Medical condition</p>

                              </div>
                          {ainsights?.health?.medical_condition&& <div className="bg-white-100 border border-[#EBEBEB] rounded-[8px] shadow-sm">
                                  <p className='p-12-scale-mob md:p-10-scale-924 text-16-scale-mob md:text-16-scale-924'>
                                    {ainsights?.health?.medical_condition}
                                  </p>
                              </div>}
                           

                      </div>
                      <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob flex flex-col form-gap-20">
                         
                              <div className="flex items-center gap-x-[10px] ">
                                  <PillsIcon />
                                  <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[10px]'>Medication</p>
                                  
                              </div>
                              {ainsights?.health?.medication&&<div className="bg-white-100 border border-[#EBEBEB] rounded-[8px] shadow-sm">
                                  <p className='p-12-scale-mob md:p-10-scale-924 text-16-scale-mob md:text-16-scale-924'>
                                    {ainsights?.health?.medication}
                                  </p>
                              </div>}

                      </div>

         
            </div>
</div>
  )
}

export default Health